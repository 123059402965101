import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import _ from "lodash";
import striptags from 'striptags';
import reactHtmlParser from "react-html-parser";
import QuestionSummaryTable from "./QuestionSummaryTable";

class QuestionSummarySingle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };
  }

  render() {
    const {questionSummary} = this.props;
    const data = _.concat(
      questionSummary.data.answers.map(answer => {
        return {
          content: answer.content,
          description: answer.description,
          value: answer.value,
          isAnswer: true,
          isOpen: answer.isOpen,
          openDetails: answer.openDetails
        };
      }),
      questionSummary.data.excludings.map(e => {
        return {
          content: e.content,
          value: e.value
        }
      })
    );
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={data.map(d => {
          return {
            x: reactHtmlParser(striptags((questionSummary.question.answersImage && d.isAnswer) ? d.description : d.content))[0],
            value: parseInt(d.value)
          }
        })}/>

        {this.props.table && <React.Fragment>
          <QuestionSummaryTable data={data} questionSummary={questionSummary} />
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummarySingle.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummarySingle.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummarySingle;
