import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Row, Col, Card, CardBody, ButtonGroup, Tooltip} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import "./PlansTable.scss"

class PlansTable extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      tooltip: false,
      period: props.isCompany ? 'annual' : 'month'
    };

    this.getMonthlyPriceForPeriod = this.getMonthlyPriceForPeriod.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip(value){
    this.setState({
      tooltip: this.state.tooltip === value ? false : value
    });
  }

  getMonthlyPriceForPeriod(plan) {
    switch (this.state.period) {
      case 'month':
        return Math.round(plan.price) / 100;
      case 'quarterly':
        return Math.round(plan.quarterlyPrice / 300 * 100) / 100;
      case 'annual':
        return Math.round(plan.annualPrice / 1200 * 100) / 100;
    }
  }

  renderNumberTemplate(value){
    if(value === -1){
      return <FormattedMessage tag="strong" id="plansTable.plan.noAccess"/>;
    }else if(value === 0){
      return <FormattedMessage tag="strong" id="plansTable.plan.unlimit"/>;
    }else{
      return value;
    }
  }

  render() {
    const {intl, plans, prizes, isCompany} = this.props;

    return (
      <React.Fragment>
        {isCompany && <Row className="mb-4">
          <Col className="text-center">
            <ButtonGroup>
              <Button onClick={() => this.setState({period: 'month'})} active={this.state.period === 'month'}><FormattedMessage id="planDetails.info.month"/></Button>
              <Button onClick={() => this.setState({period: 'quarterly'})} active={this.state.period === 'quarterly'}><FormattedMessage id="planDetails.info.quarterly"/></Button>
              <Button onClick={() => this.setState({period: 'annual'})} active={this.state.period === 'annual'}><FormattedMessage id="planDetails.info.annual"/></Button>
            </ButtonGroup>
          </Col>
        </Row>}
        <Row id="table-plan">
          <Col xs={12} xl={3} className="d-none">
            <Card
              className="plan-wrap w-100 plan-title"
              style={{
                marginTop: prizes.length > 0 ? '134px' : '93px'
              }}
            >
              <CardBody className="text-right">
                <p><FormattedMessage id="plansTable.plan.survey.responsesMax"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.surveysActiveMax"/></p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.groups"/>
                  <i id="plan-groups" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-groups'}
                           target={'plan-groups'}
                           toggle={() => this.toggleTooltip('plan-groups')}
                  ><FormattedMessage id="plansTable.plan.groups.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.languages"/>
                  <i id="plan-survey-languages" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-languages'}
                           target={'plan-survey-languages'}
                           toggle={() => this.toggleTooltip('plan-survey-languages')}
                  ><FormattedMessage id="plansTable.plan.survey.languages.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.dateEndMax"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.questionsAvailable"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.multimedia.maxTotalSizeKb"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.respondentBase.basesMax"/></p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.piping"/>
                  <i id="plan-survey-piping" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-piping'}
                           target={'plan-survey-piping'}
                           toggle={() => this.toggleTooltip('plan-survey-piping')}
                  ><FormattedMessage id="plansTable.plan.survey.piping.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.collectorsMax"/>
                  <i id="plan-survey-collectorsMax" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-collectorsMax'}
                           target={'plan-survey-collectorsMax'}
                           toggle={() => this.toggleTooltip('plan-survey-collectorsMax')}
                  ><FormattedMessage id="plansTable.plan.survey.collectorsMax.tooltip"/></Tooltip>
                </p>
                <hr/>




                <h4>Informacje<br />ogólne</h4>
                <hr/>
                <p>Brak reklam</p>
                <hr/>
                <p>
                  <span>Kod rabatowy</span>
                  <i id="plan-compatibility" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-compatibility'}
                           target={'plan-compatibility'}
                           toggle={() => this.toggleTooltip('plan-compatibility')}
                  >Przy zakupie tego planu możesz skorzystać z kodu rabatowego</Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.compatibility"/>
                  <i id="plan-compatibility" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-compatibility'}
                           target={'plan-compatibility'}
                           toggle={() => this.toggleTooltip('plan-compatibility')}
                  ><FormattedMessage id="plansTable.plan.compatibility.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.widgets"/>
                  <i id="plan-widgets" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-widgets'}
                           target={'plan-widgets'}
                           toggle={() => this.toggleTooltip('plan-widgets')}
                  ><FormattedMessage id="plansTable.plan.widgets.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.clients"/>
                  <i id="plan-clients" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-clients'}
                           target={'plan-clients'}
                           toggle={() => this.toggleTooltip('plan-clients')}
                  ><FormattedMessage id="plansTable.plan.clients.tooltip"/></Tooltip>
                </p>
                <hr/>

                <h4>Projektowanie ankiety</h4>
                <hr/>
                <p>Intuicyjny kreator ankiet drag&drop</p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.integrations"/>
                  <i id="plan-survey-integrations" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-integrations'}
                           target={'plan-survey-integrations'}
                           toggle={() => this.toggleTooltip('plan-survey-integrations')}
                  ><FormattedMessage id="plansTable.plan.survey.integrations.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.filters"/>
                  <i id="plan-survey-filters" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-filters'}
                           target={'plan-survey-filters'}
                           toggle={() => this.toggleTooltip('plan-survey-filters')}
                  ><FormattedMessage id="plansTable.plan.survey.filters.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.quotas"/>
                  <i id="plan-survey-quotas" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-quotas'}
                           target={'plan-survey-quotas'}
                           toggle={() => this.toggleTooltip('plan-survey-quotas')}
                  ><FormattedMessage id="plansTable.plan.survey.quotas.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.globalQuestionTemplates"/>
                  <i id="plan-survey-globalQuestionTemplates" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-globalQuestionTemplates'}
                           target={'plan-survey-globalQuestionTemplates'}
                           toggle={() => this.toggleTooltip('plan-survey-globalQuestionTemplates')}
                  ><FormattedMessage id="plansTable.plan.survey.globalQuestionTemplates.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.userQuestionTemplates"/>
                  <i id="plan-survey-userQuestionTemplates" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-userQuestionTemplates'}
                           target={'plan-survey-userQuestionTemplates'}
                           toggle={() => this.toggleTooltip('plan-survey-userQuestionTemplates')}
                  ><FormattedMessage id="plansTable.plan.survey.userQuestionTemplates.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.inaccessibilityText"/></p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.blocks"/>
                  <i id="plan-survey-blocks" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-blocks'}
                           target={'plan-survey-blocks'}
                           toggle={() => this.toggleTooltip('plan-survey-blocks')}
                  ><FormattedMessage id="plansTable.plan.survey.blocks.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.sets"/>
                  <i id="plan-survey-sets" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-sets'}
                           target={'plan-survey-sets'}
                           toggle={() => this.toggleTooltip('plan-survey-sets')}
                  ><FormattedMessage id="plansTable.plan.survey.sets.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.questionSettings.randCafetery"/>
                  <i id="plan-survey-questionSettings-randCafetery" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-questionSettings-randCafetery'}
                           target={'plan-survey-questionSettings-randCafetery'}
                           toggle={() => this.toggleTooltip('plan-survey-questionSettings-randCafetery')}
                  ><FormattedMessage id="plansTable.plan.survey.questionSettings.randCafetery.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.questionSettings.minMaxCafetery"/>
                  <i id="plan-survey-questionSettings-minMaxCafetery" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-questionSettings-minMaxCafetery'}
                           target={'plan-survey-questionSettings-minMaxCafetery'}
                           toggle={() => this.toggleTooltip('plan-survey-questionSettings-minMaxCafetery')}
                  ><FormattedMessage id="plansTable.plan.survey.questionSettings.minMaxCafetery.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.multimedia.maxImageFileSizeKb"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.multimedia.maxVideoFileSizeKb"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.multimedia.maxAudioFileSizeKb"/></p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.questionSettings.supplementMax"/>
                  <i id="plan-survey-questionSettings-supplementMax" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-questionSettings-supplementMax'}
                           target={'plan-survey-questionSettings-supplementMax'}
                           toggle={() => this.toggleTooltip('plan-survey-questionSettings-supplementMax')}
                  ><FormattedMessage id="plansTable.plan.survey.questionSettings.supplementMax.tooltip"/></Tooltip>
                </p>
                <hr/>

                <h4>Gromadzenie<br />danych</h4>
                <hr/>
                <p>Umieszczanie ankiety w SM</p>
                <hr/>
                <p>Umieszczanie ankiety na www</p>
                <hr/>
                <p>SSL</p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.cookieBlockade"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.ipBlockade"/></p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.redirects"/>
                  <i id="plan-survey-redirects" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-redirects'}
                           target={'plan-survey-redirects'}
                           toggle={() => this.toggleTooltip('plan-survey-redirects')}
                  ><FormattedMessage id="plansTable.plan.survey.redirects.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.pause"/></p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.limit"/>
                  <i id="plan-survey-limit" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-limit'}
                           target={'plan-survey-limit'}
                           toggle={() => this.toggleTooltip('plan-survey-limit')}
                  ><FormattedMessage id="plansTable.plan.survey.limit.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.respondentBase.baseAddressesMax"/>
                  <i id="plan-respondentBase-baseAddressesMax" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-respondentBase-baseAddressesMax'}
                           target={'plan-respondentBase-baseAddressesMax'}
                           toggle={() => this.toggleTooltip('plan-respondentBase-baseAddressesMax')}
                  ><FormattedMessage id="plansTable.plan.respondentBase.baseAddressesMax.tooltip"/></Tooltip>
                </p>
                <hr/>
                <p>
                  <FormattedMessage id="plansTable.plan.survey.collectorsTokensMax"/>
                  <i id="plan-survey-collectorsTokensMax" className="fas fa-question-circle ml-2"/>
                  <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-survey-collectorsTokensMax'}
                           target={'plan-survey-collectorsTokensMax'}
                           toggle={() => this.toggleTooltip('plan-survey-collectorsTokensMax')}
                  ><FormattedMessage id="plansTable.plan.survey.collectorsTokensMax.tooltip"/></Tooltip>
                </p>
                <hr/>


                <h4>Wyniki<br />raportowanie</h4>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.survey.analysesMax"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.analysis.exportResponseTime"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.analysis.filtering"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.analysis.exportTypes-excel"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.analysis.exportTypes-spss"/></p>
                <hr/>
                <p><FormattedMessage id="plansTable.plan.analysis.exportTypes-FREQUENCY_RESPONSES"/></p>
                <hr/>
                <p> </p>







              </CardBody>
            </Card>
          </Col>

          {plans.map((plan, id) => {
              let prize = prizes.find(prize => prize.plan.id === plan.id);

              return <Col xs={12} key={id} xl={3} className="d-block align-items-md-stretch plan">
                <Card className={'plan-wrap ' + ((!prize && plan.id === this.props.bestseller) ? 'active bestseller' : '')}>
                  <div className="bg pointer" onClick={() => this.props.onChoosePlan(plan, this.state.period)}>
                    <strong>{plan.name}{plan.price === 0 && <span id={'plan-'+plan.id+'-free-info'} className="ml-2">
                      <i className="fas fa-question-circle" />
                      <Tooltip
                        delay={0}
                        placement="top"
                        isOpen={this.state.tooltip === 'plan-'+plan.id+'-free-info'}
                        target={'plan-'+plan.id+'-free-info'}
                        toggle={() => this.toggleTooltip('plan-'+plan.id+'-free-info')}
                      >Tylko dla panelistów swpanel lub studentswatch</Tooltip>

                    </span>}</strong>
                    <h3 style={{fontSize: '20px'}}>{this.getMonthlyPriceForPeriod(plan)} <FormattedMessage id="plansTable.plan.currency.pln">{msg => msg}</FormattedMessage></h3>
                    {prizes.length > 0 && <h3 style={{fontSize: '20px'}}>
                      lub {prize ? prize.cost : 0} <img src={'/dashboard/img/swpanel-cashPoints.png'} style={{width: '50px'}} />
                    </h3>}
                  </div>
                  <CardBody>
                    <strong><FormattedMessage id="plansTable.plan.survey.responsesMax"/>: {this.renderNumberTemplate(plan.survey.responsesMax)}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.survey.questionsMax"/>: {this.renderNumberTemplate(plan.survey.questionsMax)}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.surveysActiveMax"/>: {this.renderNumberTemplate(plan.surveysActiveMax)}</strong>
                    <hr/>
                    <strong>{plan.groups ? <React.Fragment><FormattedMessage id="plansTable.plan.groups"/>
                      <i id={'plan-'+plan.id+'-groups'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-groups'}
                               target={'plan-'+plan.id+'-groups'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-groups')}
                      ><FormattedMessage id="plansTable.plan.groups.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.languages ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.languages"/>
                      <i id={'plan-'+plan.id+'-survey-languages'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-languages'}
                               target={'plan-'+plan.id+'-survey-languages'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-languages')}
                      ><FormattedMessage id="plansTable.plan.survey.languages.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.survey.dateEndMax"/>: {this.renderNumberTemplate(plan.survey.dateEndMax)}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.survey.questionsAvailable"/>: {plan.survey.questionsAvailable.length}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.multimedia.maxTotalSizeKb"/>: {plan.multimedia.maxTotalSizeKb > 0 ? this.renderNumberTemplate(plan.multimedia.maxTotalSizeKb / 1024) + ' MB' : <FormattedMessage id="plansTable.plan.unlimit"/>}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.respondentBase.basesMax"/>: {this.renderNumberTemplate(plan.respondentBase.basesMax)}</strong>
                    <hr/>
                    <strong>{plan.survey.piping ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.piping"/>
                      <i id={'plan-'+plan.id+'-survey-piping'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-piping'}
                               target={'plan-'+plan.id+'-survey-piping'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-piping')}
                      ><FormattedMessage id="plansTable.plan.survey.piping.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.survey.collectorsMax"/>
                      <i id={'plan-'+plan.id+'-survey-collectorsMax'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-collectorsMax'}
                               target={'plan-'+plan.id+'-survey-collectorsMax'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-collectorsMax')}
                      ><FormattedMessage id="plansTable.plan.survey.collectorsMax.tooltip"/></Tooltip>: {this.renderNumberTemplate(plan.survey.collectorsMax)}</strong>
                    <hr/>



                    <h4>Informacje<br />ogólne</h4>
                    <hr/>
                    <strong>Brak reklam</strong>
                    <hr/>
                    <strong>{plan.discountable ? <React.Fragment><span>Kod rabatowy</span>
                      <i id={'plan-'+plan.id+'-compatibility'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-compatibility'}
                               target={'plan-'+plan.id+'-compatibility'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-compatibility')}
                      >Przy zakupie tego planu możesz skorzystać z kodu rabatowego</Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.compatibility"/>
                      <i id={'plan-'+plan.id+'-compatibility'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-compatibility'}
                               target={'plan-'+plan.id+'-compatibility'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-compatibility')}
                      ><FormattedMessage id="plansTable.plan.compatibility.tooltip"/></Tooltip></strong>
                    <hr/>
                    <strong>{plan.widgets ? <React.Fragment><FormattedMessage id="plansTable.plan.widgets"/>
                      <i id={'plan-'+plan.id+'-widgets'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-widgets'}
                               target={'plan-'+plan.id+'-widgets'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-widgets')}
                      ><FormattedMessage id="plansTable.plan.widgets.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.clients ? <React.Fragment><FormattedMessage id="plansTable.plan.clients"/>
                      <i id={'plan-'+plan.id+'-clients'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-clients'}
                               target={'plan-'+plan.id+'-clients'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-clients')}
                      ><FormattedMessage id="plansTable.plan.clients.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>

                    <h4>Projektowanie ankiety</h4>
                    <hr/>
                    <strong>Intuicyjny kreator ankiet drag&drop</strong>
                    <hr/>
                    <strong>{plan.survey.integrations ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.integrations"/>
                      <i id={'plan-'+plan.id+'-survey-integrations'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-integrations'}
                               target={'plan-'+plan.id+'-survey-integrations'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-integrations')}
                      ><FormattedMessage id="plansTable.plan.survey.integrations.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.filters ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.filters"/>
                      <i id={'plan-'+plan.id+'-survey-filters'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-filters'}
                               target={'plan-'+plan.id+'-survey-filters'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-filters')}
                      ><FormattedMessage id="plansTable.plan.survey.filters.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.quotas ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.quotas"/>
                      <i id={'plan-'+plan.id+'-survey-quotas'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-quotas'}
                               target={'plan-'+plan.id+'-survey-quotas'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-quotas')}
                      ><FormattedMessage id="plansTable.plan.survey.quotas.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.globalQuestionTemplates ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.globalQuestionTemplates"/>
                      <i id={'plan-'+plan.id+'-survey-globalQuestionTemplates'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-globalQuestionTemplates'}
                               target={'plan-'+plan.id+'-survey-globalQuestionTemplates'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-globalQuestionTemplates')}
                      ><FormattedMessage id="plansTable.plan.survey.globalQuestionTemplates.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.userQuestionTemplates ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.userQuestionTemplates"/>
                      <i id={'plan-'+plan.id+'-survey-userQuestionTemplates'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-userQuestionTemplates'}
                               target={'plan-'+plan.id+'-survey-userQuestionTemplates'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-userQuestionTemplates')}
                      ><FormattedMessage id="plansTable.plan.survey.userQuestionTemplates.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.inaccessibilityText ? <FormattedMessage id="plansTable.plan.survey.inaccessibilityText"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.blocks ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.blocks"/>
                      <i id={'plan-'+plan.id+'-survey-blocks'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-blocks'}
                               target={'plan-'+plan.id+'-survey-blocks'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-blocks')}
                      ><FormattedMessage id="plansTable.plan.survey.blocks.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.sets ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.sets"/>
                      <i id={'plan-'+plan.id+'-survey-sets'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-sets'}
                               target={'plan-'+plan.id+'-survey-sets'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-sets')}
                      ><FormattedMessage id="plansTable.plan.survey.sets.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.questionSettings.randCafetery ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.questionSettings.randCafetery"/>
                      <i id={'plan-'+plan.id+'-survey-questionSettings-randCafetery'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-questionSettings-randCafetery'}
                               target={'plan-'+plan.id+'-survey-questionSettings-randCafetery'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-questionSettings-randCafetery')}
                      ><FormattedMessage id="plansTable.plan.survey.questionSettings.randCafetery.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.questionSettings.minMaxCafetery ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.questionSettings.minMaxCafetery"/>
                      <i id={'plan-'+plan.id+'-survey-questionSettings-minMaxCafetery'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-questionSettings-minMaxCafetery'}
                               target={'plan-'+plan.id+'-survey-questionSettings-minMaxCafetery'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-questionSettings-minMaxCafetery')}
                      ><FormattedMessage id="plansTable.plan.survey.questionSettings.minMaxCafetery.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.multimedia.maxImageFileSizeKb"/>: {plan.multimedia.maxImageFileSizeKb > 0 ? this.renderNumberTemplate(plan.multimedia.maxImageFileSizeKb) + ' KB' : <FormattedMessage id="plansTable.plan.unlimit"/>}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.multimedia.maxVideoFileSizeKb"/>: {plan.multimedia.maxVideoFileSizeKb > 0 ? this.renderNumberTemplate(plan.multimedia.maxVideoFileSizeKb / 1024) + ' MB' : <FormattedMessage id="plansTable.plan.unlimit"/>}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.multimedia.maxAudioFileSizeKb"/>: {plan.multimedia.maxAudioFileSizeKb > 0 ? this.renderNumberTemplate(plan.multimedia.maxAudioFileSizeKb / 1024) + ' MB' : <FormattedMessage id="plansTable.plan.unlimit"/>}</strong>
                    <hr/>
                    <strong>{plan.survey.questionSettings.supplementMax ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.questionSettings.supplementMax"/>
                      <i id={'plan-'+plan.id+'-survey-questionSettings-supplementMax'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-questionSettings-supplementMax'}
                               target={'plan-'+plan.id+'-survey-questionSettings-supplementMax'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-questionSettings-supplementMax')}
                      ><FormattedMessage id="plansTable.plan.survey.questionSettings.supplementMax.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>


                    <h4>Gromadzenie<br />danych</h4>
                    <hr/>
                    <strong>Umieszczanie ankiety w SM</strong>
                    <hr/>
                    <strong>Umieszczanie ankiety na www</strong>
                    <hr/>
                    <strong>SSL</strong>
                    <hr/>
                    <strong>{plan.survey.cookieBlockade ? <FormattedMessage id="plansTable.plan.survey.cookieBlockade"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.ipBlockade ? <FormattedMessage id="plansTable.plan.survey.ipBlockade"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.redirects ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.redirects"/>
                      <i id={'plan-'+plan.id+'-survey-redirects'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-redirects'}
                               target={'plan-'+plan.id+'-survey-redirects'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-redirects')}
                      ><FormattedMessage id="plansTable.plan.survey.redirects.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.pause ? <FormattedMessage id="plansTable.plan.survey.pause"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.survey.limit ? <React.Fragment><FormattedMessage id="plansTable.plan.survey.limit"/>
                      <i id={'plan-'+plan.id+'-survey-limit'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-limit'}
                               target={'plan-'+plan.id+'-survey-limit'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-limit')}
                      ><FormattedMessage id="plansTable.plan.survey.limit.tooltip"/></Tooltip></React.Fragment> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.respondentBase.baseAddressesMax"/>
                      <i id={'plan-'+plan.id+'-respondentBase-baseAddressesMax'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-respondentBase-baseAddressesMax'}
                               target={'plan-'+plan.id+'-respondentBase-baseAddressesMax'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-respondentBase-baseAddressesMax')}
                      ><FormattedMessage id="plansTable.plan.respondentBase.baseAddressesMax.tooltip"/></Tooltip>: {this.renderNumberTemplate(plan.respondentBase.baseAddressesMax)}</strong>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.survey.collectorsTokensMax"/>
                      <i id={'plan-'+plan.id+'-survey-collectorsTokensMax'} className="fas fa-question-circle ml-2"/>
                      <Tooltip delay={0} placement="top" isOpen={this.state.tooltip === 'plan-'+plan.id+'-survey-collectorsTokensMax'}
                               target={'plan-'+plan.id+'-survey-collectorsTokensMax'}
                               toggle={() => this.toggleTooltip('plan-'+plan.id+'-survey-collectorsTokensMax')}
                      ><FormattedMessage id="plansTable.plan.survey.collectorsTokensMax.tooltip"/></Tooltip>: {this.renderNumberTemplate(plan.survey.collectorsTokensMax)}</strong>
                    <hr/>


                    <h4>Wyniki<br />raportowanie</h4>
                    <hr/>
                    <strong><FormattedMessage id="plansTable.plan.survey.analysesMax"/>: {this.renderNumberTemplate(plan.survey.analysesMax)}</strong>
                    <hr/>
                    <strong>{plan.analysis.exportResponseTime ? <FormattedMessage id="plansTable.plan.analysis.exportResponseTime"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.analysis.filtering ? <FormattedMessage id="plansTable.plan.analysis.filtering"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.analysis.exportTypes.indexOf('excel') > -1 ? <FormattedMessage id="plansTable.plan.analysis.exportTypes-excel"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.analysis.exportTypes.indexOf('spss') > -1 ? <FormattedMessage id="plansTable.plan.analysis.exportTypes-spss"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.analysis.exportTypes.indexOf('FREQUENCY_RESPONSES') > -1 ? <FormattedMessage id="plansTable.plan.analysis.exportTypes-FREQUENCY_RESPONSES"/> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.analysis.summary ? <span>Wykresy</span> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>
                    <strong>{plan.analysis.responsePreview ? <span>Przegląd pojedynczych wywiadów</span> : <i className="fas fa-times text-danger" />}</strong>
                    <hr/>





                    <div className="direction-group mt-4">
                      <Button color="primary" className="btn btn-primary" onClick={() => this.props.onChoosePlan(plan, this.state.period)}><FormattedMessage id="plansTable.selectPlan" /></Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
          })}
        </Row>
      </React.Fragment>
    )
  }
}

PlansTable.propTypes = {
  bestseller: PropTypes.number.isRequired,
  plans: PropTypes.array.isRequired,
  prizes: PropTypes.array.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
  isCompany: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    config: state.config.data
  }
}

export default connect(mapStateToProps)(injectIntl(PlansTable));