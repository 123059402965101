import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Col, Card, CardHeader, CardBody, CardImgOverlay, Button, CardFooter} from "reactstrap";
import LoadingSpinner from 'components/LoadingSpinner';
import {addWidget} from "scenes/Dashboard/scenes/Widgets/data/widgets/actions";
import {animateScroll as scroll} from 'react-scroll'
import CollectorsSettings from "../../../../../WidgetTypes/TypeCollectors/components/Settings";
import SurveyTableSettings from "../../../../../WidgetTypes/TypeSurveyTable/components/Settings";
import QuestionSummariesFromAnalysisSettings from "../../../../../WidgetTypes/TypeQuestionSummariesFromAnalysis/components/Settings";
import widgetTypes from "../../../../../WidgetTypes/types";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";

class Widget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      settingsModal: false,
    };

    this.hasSettings = this.hasSettings.bind(this);
    this.onClick = this.onClick.bind(this);
    this.createWidget = this.createWidget.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(field) {
    this.setState({
      [field]: !this.state[field]
    });
  }

  hasSettings() {
    return widgetTypes.find(widget => widget.type === this.props.widget.type).hasSettings;
  }

  onClick(){
    this.hasSettings() ? this.setState({settingsModal: true}) : this.createWidget();
  }

  createWidget(data = {}) {
    if(!this.state.creating) {
      let widget = {type: this.props.widget.type, ...data.widget};

      this.setState({
        creating: true,
        settingsModal: false,
      });

      this.props.addWidget(widget).then(() => {
        const items = document.querySelectorAll(".react-grid-item");
        let position = items[items.length-1].getBoundingClientRect();
        scroll.scrollTo(position.top, {containerId: 'dashboard'});
        this.props.toggleModal();
      })
        .catch(() => this.setState({creating: false}));
    }
  }

  settingsModal() {
    switch (this.props.widget.type) {
      case 'SurveyTable':
        return <SurveyTableSettings modal={this.state.settingsModal} toggle={this.toggle} widget={this.props.widget} handleSettingsSet={this.createWidget}/>;
      case 'Collectors':
        return <CollectorsSettings modal={this.state.settingsModal} toggle={this.toggle} widget={this.props.widget} handleSettingsSet={this.createWidget}/>;
      case 'QuestionSummariesFromAnalysis':
        return <QuestionSummariesFromAnalysisSettings modal={this.state.settingsModal} toggle={this.toggle} widget={this.props.widget} handleSettingsSet={this.createWidget}/>;
      default:
        return;
    }
  }

  render() {
    const {creating, settingsModal} = this.state;
    const {widget} = this.props;

    return (
      <Col sm={12} md={6} lg={4} xl={4} className="d-flex align-items-md-stretch">
        <Card className="border-l orange w-100">
          <CardHeader>
            <FormattedMessage id={widget.title}>{msg => {
              return <h4>{msg} <HelpModeIconTooltip modalHeader="Błędy w ankiecie">
                <FormattedMessage id={widget.desc} />
              </HelpModeIconTooltip></h4>
            }}</FormattedMessage>
          </CardHeader>
          <hr className="m-0" />
          <CardBody>
            <p><FormattedMessage id={widget.desc} /></p>
          </CardBody>
          <CardFooter>
            <Button className="w-100 mb-0" color="primary" onClick={() => this.onClick()}><i className="fas fa-plus" /> Utwórz</Button>
          </CardFooter>
          {creating
            ? <CardImgOverlay className="overlayer animated fadeIn"><LoadingSpinner absolute={true}/></CardImgOverlay>
            : ''
          }
        </Card>
        {settingsModal && this.settingsModal()}
      </Col>
    )
  }
}

Widget.propTypes = {
  widget: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default connect(null, {addWidget})(Widget);