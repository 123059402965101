import React, {PureComponent} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Badge, Button, Card, CardBody, CardHeader} from "reactstrap";
import QuestionSummarySettings from "./QuestionSummarySettings";
import {updateQuestionSummary} from "../../../reducer/questionSummaries/actions";
import LoadingSpinner from "components/LoadingSpinner";
import {FormattedMessage} from "react-intl";
import api from "api";
import Icon from "../../../../../../componentsReusable/Icon";
import QuestionSummaryByType from "./QuestionSummaryByType";

class QuestionSummaryCard extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      settingsOpen: false,
      settingsSaving: false,
      questionSummary: props.questionSummary,
      changedData: {},
    };

    this.toggleSettingsOpen = this.toggleSettingsOpen.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(){
    if (this.props.onStartLoading) {
      this.props.onStartLoading();
    }

    this.setState({
      loading: true,
    });

    api.analysis.questionSummary.get(this.props.questionSummary.id)
    .then(questionSummary => {
      this.setState({
        questionSummary,
        loading: false,
      });

      if (this.props.onEndLoading) {
        this.props.onEndLoading();
      }
    }).catch(() => {
      if (this.props.onEndLoading) {
        this.props.onEndLoading();
      }
    });
  }

  saveSettings() {
    const {changedData} = this.state;

    this.setState({
      settingsSaving: true,
      changedData: false,
      loading: true,
    });

    api.analysis.questionSummary.patch(changedData).then(questionSummary => {
      this.setState({
        settingsOpen: false,
        settingsSaving: false,
        loading: false,
        questionSummary,
      });
    });
  }

  toggleSettingsOpen() {
    this.setState({
      settingsOpen: !this.state.settingsOpen,
      changedData: !this.state.settingsOpen ? this.props.questionSummary : false
    });
  }

  render() {
    const {config, questionType} = this.props;
    const {loading, settingsOpen, settingsSaving, changedData, questionSummary} = this.state;

    const randomColor = '#' + (0x1000000+(Math.random()) * 0xffffff).toString(16).substr(1,6);

    return (
      <Card className="border-l" style={{borderColor: randomColor}}>
        <CardHeader className="d-flex align-items-center justify-content-between">
          {questionSummary.question.content ? <h2 dangerouslySetInnerHTML={{__html: questionSummary.question.content}} className="img-30" /> : <h2><i><FormattedMessage id="_.question.content.empty" /></i></h2>}
          <div className="d-flex flex-row flex-nowrap align-items-center download">
            {this.props.exportTypesAvailable.indexOf('FREQUENCY_RESPONSES') >= 0 && <a
              target="_blank"
              className="btn btn-secondary btn-sm mr-2"
              href={api.analysis.export(this.props.analysis.id, 'FREQUENCY_RESPONSES', this.props.analysis.lang, {qIds: [questionSummary.question.id]}, false, [])}>
              <Icon tooltip={'Pobierz rozkłady częstości'} className="fas fa-download"/>
            </a>}
            {this.props.settings && <Button color={settingsOpen ? 'primary' : 'secondary'} onClick={this.toggleSettingsOpen} size="sm" className="ml-auto mr-2 d-print-none"><i className="fa fa-cogs"/></Button>}
          </div>
        </CardHeader>
        <hr className="m-0" />
        {settingsOpen && <QuestionSummarySettings
          onChange={(changedData) => this.setState({changedData})}
          onCancel={this.toggleSettingsOpen}
          onSave={this.saveSettings}
          saving={settingsSaving}
          config={{
            chartTypes: config.questions[questionType].chartTypes,
          }}
          questionSummary={questionSummary}
        />}

        <CardBody>
          {loading ? <div className="text-center py-5 my-5">
            <LoadingSpinner/>
          </div> : <React.Fragment>
            <div className="d-flex align-content-center justify-content-center mb-3">
              <Badge color="secondary" style={{fontSize: '1em'}}>N = {this.state.questionSummary.data.n}</Badge>
            </div>

            <QuestionSummaryByType questionSummary={this.state.questionSummary} />
          </React.Fragment>}
        </CardBody>
      </Card>
    )
  }
}

QuestionSummaryCard.defaultProps = {
  settings: true,
};

QuestionSummaryCard.propTypes = {
  questionSummary: PropTypes.object.isRequired,
  settings: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    config: state.config.data,
    questionType: props.type ? props.type : state.survey.analysis.data.questions[props.questionSummary.question.id].type,
    exportTypesAvailable: state.user.userPlan.plan.analysis.exportTypes,
  }
}

export default connect(mapStateToProps, {updateQuestionSummary})(QuestionSummaryCard);
