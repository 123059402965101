import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Nav,
  NavItem,
  TabContent
} from 'reactstrap';
import "react-input-range/lib/css/index.css";
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import {connect} from "react-redux";
import ReactTable from "react-table";
import MobilePushSendModal from "./MobilePushSendModal";

class TabMobilePush extends Component {

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      duringCreateRespondents: false,
      duringSendRespondents: false,
      searching: false,
      sendings: []
    };

    this.onCreate = this.onCreate.bind(this);
    this.onSendRespondents = this.onSendRespondents.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.updateStateFromData = this.updateStateFromData.bind(this);
  }

  onCreate() {
    this.setState({creating: true});

    api.surveyCollector.panel.post.mobilePush.create(this.props.collector)
      .then(collector => {
        this.setState({creating: false});
        this.props.handleUpdate(collector);
      });
  }

  onSendRespondents() {
    this.setState({duringSendRespondents: true});
  }

  fetchData() {
    const {collector} = this.props;

    this.setState({
      searching: true
    });

    api.surveyCollector.panel.post.mobilePush.get.sendings(collector)
        .then(res => {
          this.setState({
            searching: false
          });
          this.updateStateFromData(res);
        })
        .catch(e => this.setState({
          searching: false,
        }));
  }

  updateStateFromData(res) {
    this.setState({
      sendings: res
    });
  }

  render() {
    const {collector} = this.props;
    const {params} = this.props.match;
    const {count, sendings, searching, duringSendRespondents} = this.state;
    const columns = [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Stworzono',
        accessor: 'createdAt'
      },
      {
        Header: 'Wysłanych',
        accessor: 'count'
      },
      {
        Header: 'Wysłanych Android',
        accessor: 'androidCount'
      },
      {
        Header: 'Wysłanych iOS',
        accessor: 'iosCount'
      },
      {
        Header: 'Przeczytanych',
        accessor: 'readCount'
      },
      {
        Header: 'Przeczytanych android',
        accessor: 'readAndroid'
      },
      {
        Header: 'Przeczytanych iOS',
        accessor: 'readIos'
      }
    ];

    if (_.isUndefined(collector.mobilePushSettings) || collector.mobilePushSettings.length === 0) {

      return (
          <Alert color="warning" className="text-center">
            <p>Jeśli chcesz utworzyć wysyłkę mobile push do osób z targetu kliknij poniższy przycisk</p>
            <Button onClick={this.onCreate}>{this.state.creating ? <LoadingSpinner width={20} classNames="my-0"/> :
                <React.Fragment><i className="fas fa-plus"/> Utwórz wysyłkę</React.Fragment>}</Button>
          </Alert>
      )
    }

    return (
      <React.Fragment>
        <div>
          <Button className={"ml-2"} color="primary" onClick={this.onSendRespondents}>
            Wyślij do respondentów
          </Button>
        </div>
        <ReactTable
            minRows={0}
            className={'table-wrap'}
            style={{zIndex: 0}}
            data={sendings}
            columns={columns}
            showPagination={false}
            loading={searching}
            onFetchData={this.fetchData}
            rowsCount={parseInt(count)}
            manual
        />
        {duringSendRespondents && <MobilePushSendModal onCancel={() => {
            this.setState({duringSendRespondents: false})
            this.fetchData();
        }} visible={duringSendRespondents} collector={collector} />}
      </React.Fragment>
    )
  }
}

TabMobilePush.defaultProps = {
  mobilePushSettings: {}
};

TabMobilePush.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      survey: PropTypes.string.isRequired,
      collector: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  collector: PropTypes.shape({
    mobilePushSettings: PropTypes.shape({

    }),
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

function mapStateToProps(state, props) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(TabMobilePush);