import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Button, Modal, ModalBody, ModalHeader, Tooltip} from "reactstrap";
import Video from "./video";

class PreviewButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      previewModal: false
    };
  }

  toggleTooltip() {
    this.setState({
      tooltip: !this.state.tooltip
    });
  }

  toggleModal() {
    this.setState({
      previewModal: !this.state.previewModal
    });
  }

  configPlayer(api) {
    if (this.props.question.required) {
      api.on("beforeseek", e => {
        e.preventDefault();
      });
    }
  }

  render() {
    let { tooltip, previewModal } = this.state;
    let { question } = this.props;

    if (!question.video) {
      return null;
    }

    const style = {
      width: question.playerWidth,
      height: question.playerHeight,
      maxWidth: 760
    };

    const modal =
      <Modal
        isOpen={previewModal}
        size="lg"
        toggle={() => this.toggleModal()}
        fade={false}
      >
        <ModalHeader toggle={() => this.toggleModal()} tag="h2"><FormattedMessage id='buttons.previewVideoButton.modal.modalHeader' /></ModalHeader>
        <hr className="my-0" />
      <ModalBody>
        <Video question={question}/>
      </ModalBody>
      </Modal>
    ;

    return (
      <React.Fragment>
        <Button className={this.props.className} id={"preview-" + question.id} onClick={() => this.toggleModal()}>
          <i className="fas fa-play" />
          <Tooltip
            delay={0}
            placement="top"
            isOpen={tooltip}
            target={"preview-" + question.id}
            toggle={() => this.toggleTooltip()}
          >
            <FormattedMessage id="buttons.previewVideoButton.button.tooltip"/>
          </Tooltip>
          {modal}
        </Button>

      </React.Fragment>
    )
  }
}

PreviewButton.defaultProps = {
  className: ""
};

PreviewButton.propTypes = {
  question: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default PreviewButton;