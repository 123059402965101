import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import { Col, Form, FormGroup, Input, Label} from "reactstrap";
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from "react-select";
import { connect } from 'react-redux';

class CompanyForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {
        firstname: props.user ? props.user.firstname : '',
        lastname: props.user ? props.user.lastname : '',
        username: props.user ? props.user.username : '',
        company: props.user ? props.user.company : '',
        nip: props.user ? props.user.nip : '',
        trade: props.user ? props.user.trade : Object.values(props.trades)[0].id,
        street: props.user ? props.user.street : '',
        postalCode: props.user ? props.user.postalCode : '',
        city: props.user ? props.user.city : '',
        userPlan: {
          expireDate: moment(props.user ? props.user.userPlan.expireDate : new Date()).format('YYYY-MM-DD'),
          plan: (props.user && props.user.userPlan.plan) ? props.user.userPlan.plan.id : null
        },
      }
    };

    if (!props.user) {
      this.state.user.password = '';
      this.state.user.email = '';
    }

    this.onChange = this.onChange.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.onPlanExpireDateChange = this.onPlanExpireDateChange.bind(this);
    this.onSelectCity = this.onSelectCity.bind(this);
  }

  onChange(e) {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value
      }
    });
  }

  onPlanChange(e) {
    this.setState({
      user: {
        ...this.state.user,
        userPlan: {
          ...this.state.user.userPlan,
          plan: e.value
        }
      }
    })
  }

  onPlanExpireDateChange(date) {
    this.setState({
      user: {
        ...this.state.user,
        userPlan: {
          ...this.state.user.userPlan,
          expireDate: date.format('YYYY-MM-DD')
        }
      }
    })
  }

  onSelectCity(city) {
    this.setState({
      user: {
        ...this.state.user,
        city: city.id
      }
    });

    return city.name;
  }

  render() {
    const {user} = this.state;
    const {errors, onSubmit, plans, trades} = this.props;

    const planOptions = _.map(plans, plan => {
      let name = plan.name;
      if (plan.demo) {
        name += ' - demo';
      }
      return {label: name, value: plan.id}
    });

    const tradeOptions = _.map(trades, trade => {
      return {label: trade.name, value: trade.id}
    });

    return (
      <Form onSubmit={e => onSubmit(e, this.state)}>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.firstname" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="firstname" id="firstname" value={user.firstname} invalid={!!errors.firstname} onChange={this.onChange}/>
            {errors.firstname && <p className="help-block text-danger">{errors.firstname}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.lastname" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="lastname" id="lastname" value={user.lastname} invalid={!!errors.lastname} onChange={this.onChange}/>
            {errors.lastname && <p className="help-block text-danger">{errors.lastname}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.username" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="username" id="username" value={user.username} invalid={!!errors.username} onChange={this.onChange}/>
            {errors.username && <p className="help-block text-danger">{errors.username}</p>}
          </Col>
        </FormGroup>
        {!this.props.user && <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.email" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="email" name="email" id="email" value={user.email} invalid={!!errors.email} onChange={this.onChange}/>
            {errors.email && <p className="help-block text-danger">{errors.email}</p>}
          </Col>
        </FormGroup>}
        {!this.props.user && <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.password" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="password" name="password" id="password" value={user.password} invalid={!!errors.password} onChange={this.onChange}/>
            {errors.password && <p className="help-block text-danger">{errors.password}</p>}
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.company" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="company" id="company" value={user.company} invalid={!!errors.company} onChange={this.onChange}/>
            {errors.company && <p className="help-block text-danger">{errors.company}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.nip" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="nip" id="nip" value={user.nip} invalid={!!errors.nip} onChange={this.onChange}/>
            {errors.nip && <p className="help-block text-danger">{errors.nip}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.trade" /></Label>
          <Col lg={9} className="pt-1">
            <Select
              isClearable={false}
              value={_.find(tradeOptions, trade => trade.value === user.trade)}
              options={tradeOptions}
              onChange={e => this.onChange({target: {name: 'trade', value: e.value}})}
            />
            {errors.trade && <p className="help-block text-danger">{errors.trade}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.street" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="street" id="street" value={user.street} invalid={!!errors.street} onChange={this.onChange}/>
            {errors.street && <p className="help-block text-danger">{errors.street}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.postalCode" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="postalCode" id="postalCode" value={user.postalCode} invalid={!!errors.postalCode} onChange={this.onChange}/>
            {errors.postalCode && <p className="help-block text-danger">{errors.postalCode}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.city" /></Label>
          <Col lg={9} className="pt-1">
            <Input type="text" name="city" id="city" value={user.city} invalid={!!errors.city} onChange={this.onChange}/>
            {errors.city && <p className="help-block text-danger">{errors.city}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.plan" /></Label>
          <Col lg={9} className="pt-1">
            <Select
              isClearable={false}
              value={user.userPlan ? _.find(planOptions, option => option.value === user.userPlan.plan) : null}
              options={planOptions}
              onChange={this.onPlanChange}
            />
            {errors.userPlan && errors.userPlan.plan && <p className="help-block text-danger">{errors.userPlan.plan}</p>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label lg={3} className="text-lg-right"><FormattedMessage id="admin.companies.companyForm.label.planExpireDate" /></Label>
          <Col lg={9} className="pt-1">
            <DatePicker
              selected={(user.userPlan && user.userPlan.expireDate) ? moment(user.userPlan.expireDate) : null}
              onChange={this.onPlanExpireDateChange}
              dateFormat="Y-MM-DD"
              locale={localStorage.language}
              dropdownMode="select"
            />
            {errors.userPlan && errors.userPlan.expireDate && <p className="help-block text-danger">{errors.userPlan.expireDate}</p>}
          </Col>
        </FormGroup>
      </Form>
    )
  }
}

CompanyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object,
  plans: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    plans: state.admin.plans.data.plans,
    trades: state.config.data.trades
  }
}

export default connect(mapStateToProps, {}, null, {forwardRef: true})(CompanyForm);