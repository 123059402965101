import React, {Component} from 'react';
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import appRoutes from "appRoutes";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {userLogout} from "reducers/user/actions";
import ButtonCreateSurvey from "../../../ButtonCreateSurvey";
import PropTypes from "prop-types";
import {isWebview} from "../../../../services/isWebview";

class NavigationDefault extends Component {

  static toggleNavbar() {
    let element = document.getElementById('mainNav').classList;

    element.contains('show') ? element.remove('show') : element.add('show');
  }

  render() {
    const {plan, user} = this.props;

    const isRespondent = user.type === 'Respondent';
    const isCompany = user.type === 'Company';

    let additionalLink = false;
    let u = user;
    if(user.userGroups.length > 0){
      u = user.userGroups[0].group.user;
    }
    if(u && u.appAnkieteoTopLinkName && u.appAnkieteoTopLinkUrl){
      additionalLink = {
        url: u.appAnkieteoTopLinkUrl,
        name: u.appAnkieteoTopLinkName,
      }
    }

    return (
      <Nav className="navbar-nav ml-4 mr-4 ml-lg-5 align-items-lg-baseline">
        <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.surveys.list} onClick={NavigationDefault.toggleNavbar}><FormattedMessage id="navigation.default.surveyList" /><span className="sr-only"><FormattedMessage id="navigation.default.surveyList" /></span></NavLink></NavItem>
        {(plan && plan.survey.collectorSmsAccess)
          ? <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.respondentBaseChoose} onClick={NavigationDefault.toggleNavbar}>Bazy respondentów <span className="sr-only">Bazy respondentów</span></NavLink></NavItem>
          : <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.respondentBase.list} onClick={NavigationDefault.toggleNavbar}>Bazy respondentów <span className="sr-only">Bazy respondentów</span> <span className="sr-only">Bazy respondentów <span className="sr-only">Bazy respondentów</span></span></NavLink></NavItem>
        }
        {plan && plan.clients && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.clients.list} onClick={NavigationDefault.toggleNavbar}><FormattedMessage id="navigation.default.clients" /> <span className="sr-only"><FormattedMessage id="navigation.default.clients" /></span></NavLink></NavItem>}
        {plan && plan.analysis.dictionaries && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.dictionaries.list} onClick={NavigationDefault.toggleNavbar}><FormattedMessage id="navigation.default.dictionaries"/> <span className="sr-only"><FormattedMessage id="navigation.default.dictionaries"/></span></NavLink></NavItem>}
        {plan && plan.surveySwitcherCheck && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.surveySwitcherChecks.list} onClick={NavigationDefault.toggleNavbar}>Ping</NavLink></NavItem>}
        {plan && plan.surveySendEmailStats && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.surveySendEmailStats} onClick={NavigationDefault.toggleNavbar}><FormattedMessage id="navigation.default.surveySendEmailStats"/> <span className="sr-only"><FormattedMessage id="navigation.default.surveySendEmailStats"/></span></NavLink></NavItem>}
        {plan && plan.allQuestionsResult && (this.props.user.userGroups.length === 0 || (this.props.user.userGroups[0].privilageSurveyShareDefault && this.props.user.userGroups[0].privilageSurveyShareDefault.accessQuestionsSurveysResults)) && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.questionsResult.list} onClick={NavigationDefault.toggleNavbar}><FormattedMessage id="navigation.default.questionsResult"/> <span className="sr-only"><FormattedMessage id="navigation.default.questionsResult"/></span></NavLink></NavItem>}
        {plan && plan.survey.reportEnable && <NavItem className="py-2"><NavLink className="nav-link" to={appRoutes.surveys.reports} onClick={NavigationDefault.toggleNavbar}>Zgłoszenia błędów</NavLink></NavItem>}
        {additionalLink
          && <NavItem className="py-2"><a className="nav-link" href={additionalLink.url} target="_blank">{additionalLink.name}</a></NavItem>}

        <NavItem className="d-lg-none py-2" onClick={NavigationDefault.toggleNavbar}><ButtonCreateSurvey /></NavItem>

        <NavItem className="d-lg-none py-2"><NavLink className="nav-link" to={appRoutes.account.home} onClick={NavigationDefault.toggleNavbar}><FormattedMessage id="header.profile" /> <span className="sr-only"><FormattedMessage id="header.profile" /></span></NavLink></NavItem>

        <NavItem className="d-none py-2"><div className="nav-link" onClick={(e) => {
          e.preventDefault();

          CKFinder.popup({
            width: 1014,
            height: 700,
          });
        }}>Multimedia <span className="sr-only">Multimedia</span></div></NavItem>

        {isWebview() === false && <NavItem id="mobile-logout" className="d-lg-none py-2"><div className="nav-link" onClick={() => this.props.userLogout()}><FormattedMessage id="header.logout" /> <span className="sr-only"><FormattedMessage id="header.logout" /></span></div></NavItem>}
      </Nav>
    );
  }
}

NavigationDefault.propTypes = {
  plan: PropTypes.shape({
    clients: PropTypes.bool.isRequired,
  }),
};

function mapStateToProps(state) {
  return {
    user: state.user,
    plan: 'userPlan' in state.user ? state.user.userPlan.plan : null
  }
}

export default connect(mapStateToProps, {userLogout})(NavigationDefault);