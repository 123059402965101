export default [
  {
    type: 'SurveyTable',
    hasSettings: true,
    title: 'widgets.widgetTypes.surveyTable.title',
    desc: 'widgets.widgetTypes.surveyTable.desc'
  },
  {
    type: 'Collectors',
    hasSettings: true,
    title: 'widgets.widgetTypes.collectors.title',
    desc: 'widgets.widgetTypes.collectors.desc'
  },
  {
    type: 'QuestionSummariesFromAnalysis',
    hasSettings: true,
    title: 'widgets.widgetTypes.questionSummariesFromAnalysis.title',
    desc: 'widgets.widgetTypes.questionSummariesFromAnalysis.desc'
  }
]