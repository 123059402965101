import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {deleteUser} from "reducers/user/actions";
import {Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import ProfileForm from "./ProfileForm";
import setAuthorizationHeader from "utils/setAuthorizationHeader";
import {userLoggedOut} from "reducers/user/reducer";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import ProfileFormPasswordUpdate from "./ProfileFormPasswordUpdate";
import ProfileFormEmailChange from "./ProfileFormEmailChange";
import moment from "moment";
import {isWebview} from "../../../services/isWebview";

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalDelete: false,
      deleting: false,
    };

    this.deleteUser = this.deleteUser.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
  }

  componentDidMount() {
  }

  deleteUser(){
    this.setState({
      deleting: true
    });

    this.props.deleteUser().then(user => {
      localStorage.removeItem('jwt');
      localStorage.setItem('deleteAt', moment(user.deletedDate).format('YYYY-MM-DD'));
      setAuthorizationHeader();
      this.props.userLoggedOut();
    }).catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modalDelete: !this.state.modalDelete
    })
  }

  render() {
    const {user} = this.props;
    const {modalDelete, deleting} = this.state;

    const modal = <Modal isOpen={modalDelete} toggle={() => this.toggleModalDelete()} className={'modal-danger'}>
      <ModalHeader toggle={() => this.toggleModalDelete()} tag="h2">Czy na pewno chcesz usunąć swoje konto?</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p>Czy jesteś pewien, że chcesz skasować konto? Jeśli potwierdzisz usunięcie konta, rozpocznie się 7-dniowy proces dezaktywacji, po którym Twoje dane osobowe zostaną bezpowrotnie skasowane, utracisz również dostęp do swoich projektów. Jeśli się rozmyślisz, w każdym momencie możesz przerwać ten proces.
        </p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggleModalDelete()}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.delete">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            loading={deleting}
            data-style={ZOOM_OUT}
            onClick={this.deleteUser}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <Card>
        <CardHeader><FormattedMessage tagName="h2" id="profile.cardHeader" /></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <ProfileForm user={user} />
          {isWebview() === false && <ProfileFormEmailChange user={user}/>}
          {isWebview() === false && <ProfileFormPasswordUpdate />}

          {isWebview() === false && <Button color="link" className="mt-4" style={{color: 'darkgrey'}} onClick={this.toggleModalDelete}>Usuń konto</Button>}
          {modal}
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {deleteUser, userLoggedOut})(Profile);