import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import {Alert, TabPane} from "reactstrap";
import appRoutes from "appRoutes";
import {Link} from "react-router-dom";
import QuestionSummaryCard from "./QuestionSummaryCard";
import {FormattedMessage} from "react-intl";
import {isMobile} from "react-device-detect";
import './style.scss'
import moment from "moment";
import {ButtonPrint} from "../View";
import {setSurveyNavVisibility} from "../../../../../../../../survey/src/reducers/surveyNav/actions";
import {setForcePrint} from "../../../../../../reducers/dashboardOptions/actions";

const maxLoadingParaller = 3;

class QuestionSummaries extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rendered: 5,
      renderedOnce: 1,
      currentLoading: 0,
      print: false
    };

    this.incrementCurrentLoading = this.incrementCurrentLoading.bind(this);
    this.decrementCurrentLoading = this.decrementCurrentLoading.bind(this);
    this.addToRendered = this.addToRendered.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this)
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPressed);
    if(isMobile){
      document.querySelector('header').style.display = 'none';
      document.querySelector('main').style.minHeight = '100%';
      document.querySelector('main').style.padding = '0px';
      document.querySelector('main ul.nav-tabs').style.display = 'none';
      document.querySelector('#analysis-tab-content').style.height = '100%';
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPressed);
    if(isMobile){
      document.querySelector('header').style.display = null;
      document.querySelector('main').style.minHeight = null;
      document.querySelector('main').style.padding = null;
      document.querySelector('main ul.nav-tabs').style.display = null;
      document.querySelector('#analysis-tab-content').style.height = null;
    }
  }

  incrementCurrentLoading(){
    this.setState(state => {
      return {
        currentLoading: state.currentLoading + 1
      }
    })
  }

  decrementCurrentLoading(){
    this.setState(state => {
      return {
        currentLoading: state.currentLoading - 1
      }
    })
  }

  onScroll(e){
    const el = document.getElementById('question-summaries');
    const percent = parseInt(((el.scrollTop + el.offsetHeight) / el.scrollHeight) * 100);

    if(percent > 70){
      this.addToRendered();
    }
  }

  onKeyPressed(e) {
    const {questionSummaries, setForcePrint} = this.props;
    const {rendered } = this.state;
    if((e.ctrlKey || e.metaKey) && e.keyCode === 80){
      e.preventDefault();
      if (questionSummaries.length > rendered) {
        this.setState({rendered: questionSummaries.length, print: true})
      } else {
        setForcePrint(true)
        setTimeout(() => {
          window.print();
          setForcePrint(false);
        }, 500)
      }
    }
  }

  print() {
    const {questionSummaries, setForcePrint} = this.props;
    const {rendered } = this.state;

    if (questionSummaries.length > rendered) {
      this.setState({rendered: questionSummaries.length, print: true})
    } else {
      setForcePrint(true)
      setTimeout(() => {
        window.print();
        setForcePrint(false);
      }, 500)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.currentLoading === 1 && this.state.print) {
      this.setState({print: false})
      this.props.setForcePrint(true)
      setTimeout(() => {
        window.print();
        this.props.setForcePrint(false);
      }, 500)
    }
  }

  addToRendered(){
    const {questionSummaries} = this.props;
    const {renderedOnce, rendered, currentLoading } = this.state;

    if(rendered >= questionSummaries.length || currentLoading >= maxLoadingParaller){
      return;
    }

    this.setState({
      rendered: rendered + renderedOnce,
    })
  }

  render() {
    const {questionSummaries, plan, survey} = this.props;
    const {rendered, currentLoading} = this.state;

    if(!plan.analysis.summary){
      return <Alert color="warning" className="text-center">
        <span>Moduł wykresów nie jest dostępny w Twoim planie. <Link to={appRoutes.users.plan} style={{fontSize: '16px'}}>Kliknij, aby zmienić plan</Link></span>
      </Alert>
    }

    if(questionSummaries.length === 0){
      return <Alert color="warning" className="text-center">
        <FormattedMessage id="analysis.questionSummaries.alert.noData"/>
        {/*<span>Przepraszamy. Moduł analiz będzie dostępny w kwietniu 2020.</span>*/}
      </Alert>
    }

    const questionSummariesToRender = _.slice(questionSummaries, 0, rendered);

    return (
        <React.Fragment>
          <div className={"position-absolute no-print"} style={{right: "10px", top: "10px"}}>
            <ButtonPrint print={this.print} />
          </div>
          <TabPane
            id="question-summaries"
            className="fade show p-0 pt-lg-0 pl-lg-5 pr-lg-5 pb-lg-5 d-flex flex-column mt-5"
            style={{
              height: '100%',
              overflow: 'auto',
            }}
            onScroll={() => this.onScroll()}
          >
            <div className="d-none d-print-flex justify-content-between mb-4">
              <small>{moment().format('YYYY-MM-DD')}</small>
              <small>{survey.name} | ANKIETEO</small>
            </div>
            <h2 className="d-none d-print-block w-100 text-center mb-4">Podsumowanie pytań</h2>
            {_.map(questionSummariesToRender, questionSummary => <React.Fragment>
              <div key={'qs-' + questionSummary.id}>
                <QuestionSummaryCard
                  analysis={this.props.analysis}
                  questionSummary={questionSummary}
                  onStartLoading={this.incrementCurrentLoading}
                  onEndLoading={this.decrementCurrentLoading}
                />
              </div>
            </React.Fragment>)}

            <p className="d-none d-print-block w-100 text-center">Raport wygenerowany ze strony ankieteo.pl</p>

          </TabPane>
        </React.Fragment>
    )
  }
}

QuestionSummaries.propTypes = {
  questionSummaries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired).isRequired
};

function mapStateToProps(state) {
  return {
    analysis: state.survey.analysis.data.analysis,
    questionSummaries: Object.values(state.survey.analysis.data.questionSummaries),
    plan: state.user.userPlan.plan,
    survey: state.survey.structure.data.survey,
  }
}

export default connect(mapStateToProps, {setForcePrint})(QuestionSummaries);
