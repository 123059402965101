import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import Select from "react-select";
import api from "api";

const TYPE_LABEL = {
  1 : 'SocialPoints',
  2 : 'LOT Points',
};

const WHAT_LABEL = {
  1 : 'Rejestracja',
  2 : 'Wypełnienie ankiety',
  3 : 'Uzupełnienie profilu',
  4 : 'Polecenie użytkownika',
  8 : 'Logowanie po długim czasie',
  9 : 'Codzienne logowanie',
  10 : 'Wtyczka w przeglądarce',
  11 : 'Polecanie ankiety',
  12 : 'Osiągnięcie limitu wypełnień w ankiecie',
  13 : 'Srednia ocena ankiety',
  14 : 'Udostępnienie ankiety',
  15 : 'Ranking społecznościowy',
  16 : 'Ranking Top Ankiety',
  900 : 'Inne',
  998: 'Anulowanie wymiany punktów',
  999: 'Wymiana punktów na nagrodę',
};

class PointCreateButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      saving: false,
      data: this.getDefaultData()
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.updateData = this.updateData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  getDefaultData(){
    return {
      user: 0,
      points: 1,
      type: 1,
      what: 2,
      survey: null,
    };
  }

  onSubmit(){
    const {data} = this.state;

    this.setState({
      saving: true,
    });

    api.admin.user.post.point(data)
      .then(() => {
        this.setState({
          saving: false,
          modalOpen: false,
          data: this.getDefaultData(),
        });
      })
      .catch(() => {
        this.setState({
          saving: false,
        });
      })
  }

  updateData(name, value){
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      }
    })
  }

  toggleModal() {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  render() {
    const {modalOpen, saving, data} = this.state;

    const modal = <Modal isOpen={modalOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader toggle={this.toggleModal}><h2>Dodaj punkty</h2></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <Form >
          <FormGroup row>
            <Label md={4}>Lista id użytkowników oddzielona przecinkami:</Label>
            <Col>
              <Input type="textarea" onChange={e => this.updateData('user', e.target.value)} value={data.user} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>Liczba punktów:</Label>
            <Col>
              <Input type="number" min={1} onChange={e => this.updateData('points', e.target.value)} value={data.points} />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-2">
            <Label md={4}>Typ punktów:</Label>
            <Col>
              <Select
                value={{
                  value: data.type,
                  label: TYPE_LABEL[data.type],
                }}
                options={_.map(TYPE_LABEL, (label, value) => {
                  return {
                    label,
                    value,
                  }
                })}
                onChange={(e) => this.updateData('type', e.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="mb-2">
            <Label md={4}>Otrzymane za:</Label>
            <Col>
              <Select
                value={{
                  value: data.what,
                  label: WHAT_LABEL[data.what],
                }}
                options={_.map(WHAT_LABEL, (label, value) => {
                  return {
                    label,
                    value,
                  }
                })}
                onChange={(e) => this.updateData('what', e.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4}>Id ankiety</Label>
            <Col>
              <Input type="number" onChange={e => this.updateData('survey', e.target.value)} value={data.survey} />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="_.button.save">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={saving}
            data-style={ZOOM_OUT}
            onClick={this.onSubmit}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color="primary" onClick={this.toggleModal}>
          <i className="fas fa-plus"/> Dodaj
        </Button>
        {modal}
      </React.Fragment>
    );
  }
}

PointCreateButton.propTypes = {};

export default PointCreateButton;