import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import LaddaButton, { ZOOM_OUT } from 'react-ladda';
import history from "services/history";
import appRoutes from "appRoutes";
import FormSurvey from "./components/FormSurvey";
import {fetchTags} from 'reducers/tags/actions';
import api from "../../api";
import SurveyTemplatesCreateModal from "../SurveyTemplatesCreateModal/SurveyTemplatesCreateModal";

class ButtonCreateSurvey extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      creating: false,
      errors: {},
    };

    this.submit = this.submit.bind(this);
    this.form = React.createRef();
  }

  submit(data){
    this.setState({
      creating: true,
      errors: {}
    });
    api.user.post.survey(data).then((survey) => {
      this.setState({
        modal: false,
        creating: false,
      });
      history.push(appRoutes.survey.structure(survey.id));
    }).catch(res => {
      this.setState({
        creating: false,
      });
      res.response.data.form.errors && this.setState({
        errors: res.response.data.form.errors
      });
    });
  }

  toggleModal(){
    this.setState({
      modal: !this.state.modal
    }, () => {
      if (this.state.modal && this.props.tags.length === 0) {
        this.props.fetchTags()
      }
    })
  }

  render() {
    const {creating, errors} = this.state;

    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggleModal()}
                         className={'modal-primary modal-lg'}>
      <ModalHeader tag="h2" toggle={() => this.toggleModal()}><FormattedMessage id="surveys.buttonCreateSurvey.modalHeader" /></ModalHeader>
      <hr className="m-0" />
      <ModalBody>
        <FormSurvey ref={this.form} errors={errors} submit={this.submit} tags={this.state.tags}/>

        {(this.props.plan && this.props.plan.survey.surveyTemplates) && <SurveyTemplatesCreateModal onCreated={survey => history.push(appRoutes.survey.structure(survey.id))}/>}
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button className="mb-0" color="secondary" onClick={() => this.toggleModal()}>{message}</Button>
        }</FormattedMessage>
        <FormattedMessage id="surveys.buttonCreateSurvey.modalFooter.create">{
          (message) => <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            loading={creating}
            data-style={ZOOM_OUT}
            onClick={(e) => this.form.current.onSubmit(e)}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Button color={this.props.color} className={this.props.classnames} onClick={() => this.toggleModal()}><i className="fas fa-plus" /> <FormattedMessage id="surveys.buttonCreateSurvey.button.create" /></Button>
        {modal}
      </React.Fragment>
    )
  }
}

ButtonCreateSurvey.defaultProps = {
  color: "primary",
  classnames: "w-100",
};

ButtonCreateSurvey.propTypes = {
  color: PropTypes.string,
  classnames: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    tags: state.tags,
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps, {fetchTags}, null, {forwardRef: true})(ButtonCreateSurvey);