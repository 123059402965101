import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {Col, Form, FormGroup, Label, Input, Button, Tooltip, Row} from 'reactstrap';
import ButtonGroup from "reactstrap/es/ButtonGroup";

class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: props.phone,
      tablet: props.tablet,
      desktop: props.desktop,
      android: props.android,
      ios: props.ios,
      phoneTooltip: false,
      tabletTooltip: false,
      desktopTooltip: false,
      iosTooltip: false,
      androidTooltip: false,
    };

    this.toggle = this.toggle.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggle(property) {
    this.setState({
      [property]: !this.state[property],
    }, () => {
      this.props.onChange(property, this.state[property]);
    });
  }

  toggleTooltip(type) {
    this.setState({
      [type]: !this.state[type],
    });
  }

  render() {
    return (
      <Row>
        <Col md={12} lg={8}>
          <Button className="mr-2" id="survey-devices-phone" color={this.state.phone ? 'primary' : 'secondary'} onClick={() => this.toggle('phone')}>
            <i className="fas fa-mobile-alt"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.phoneTooltip}
              target={'survey-devices-phone'}
              toggle={() => this.toggleTooltip('phoneTooltip')}
            >
              Smartfon
            </Tooltip>
          </Button>
          <Button className="mr-2" id="survey-devices-tablet" color={this.state.tablet ? 'primary' : 'secondary'} onClick={() => this.toggle('tablet')}>
            <i className="fas fa-tablet-alt"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.tabletTooltip}
              target={'survey-devices-tablet'}
              toggle={() => this.toggleTooltip('tabletTooltip')}
            >
              Tablet
            </Tooltip>
          </Button>
          <Button className="mr-2" id="survey-devices-desktop" color={this.state.desktop ? 'primary' : 'secondary'} onClick={() => this.toggle('desktop')}>
            <i className="fas fa-desktop"/>
            <Tooltip
              delay={0}
              placement="bottom"
              isOpen={this.state.desktopTooltip}
              target={'survey-devices-desktop'}
              toggle={() => this.toggleTooltip('desktopTooltip')}
            >
              Desktop
            </Tooltip>
          </Button>
        </Col>
      </Row>
    )
  }
}

Devices.propTypes = {
  onChange: PropTypes.func.isRequired,
  phone: PropTypes.bool.isRequired,
  tablet: PropTypes.bool.isRequired,
  desktop: PropTypes.bool.isRequired
};

export default Devices;