import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Badge, ListGroup, ListGroupItem} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import {connect} from "react-redux";
import Tooltip from "components/Tooltip";


class Detail extends Component {
	render() {
		const {data} = this.props;

		return (
			<div>
				<ListGroup>
					<ListGroupItem>Aktywny:
						{data.deleted ? <span className="text-danger"> Usunięty {data.deleted}</span> : ''}
						{data.ban ? <span className="text-danger"> Zbanowany: <FormattedMessage
							id={'admin.checkUser.detail.ban.category.' + data.ban.category}
						/> - {data.ban.comment ? <span> ({data.ban.comment})</span> : ''}</span> : ''}
						{!data.deleted && !data.ban ? <span className="text-success"> TAK</span> : ''}
					</ListGroupItem>
					{this.props.userLogged.roles.includes('ROLE_SUPER_ADMIN') &&
					<ListGroupItem>Hasło: {data.plainPassword ? data.plainPassword : data.passwordOldMd5 ? data.passwordOldMd5 :
						<i>Brak hasła</i>}</ListGroupItem>}
					<ListGroupItem className="d-flex flex-nowrap">
						<span>Zweryfikowany:</span>
						<div className="ml-2">
							{data.verified ? <React.Fragment>
								<Badge color="success" id={`user-${data.id}-verified`}>Tak</Badge>
								<Tooltip id={`user-${data.id}-verified`} msg={data.verifiedMsg}/>
							</React.Fragment> : 'Nie'}
						</div>
					</ListGroupItem>
					<ListGroupItem>LOT Points: {data.cashPoints}</ListGroupItem>
					<ListGroupItem>Liczba nagród: {data.prizesCount} ({data.prizesPointsSum} LOT Points)</ListGroupItem>
					<ListGroupItem>Liczba poleconych: {data.refCount} (<span
						className="text-success"
					>{data.refActiveCount}</span> | <span
						className="text-danger"
					>{data.refDeletedCount}</span>) {data.refCount > 0 &&
					<Link target="_blank" to={appRoutes.admin.users.respondents + '?ref=' + this.props.id}>Pokaż
						listę</Link>}</ListGroupItem>
					<ListGroupItem>Takie samo IP: {data.sameIp}</ListGroupItem>
					<ListGroupItem>Takie samo hasło: {data.samePassword}</ListGroupItem>
					<ListGroupItem>Ostatni reset hasła: {data.lastResetPassword || 'Brak'}</ListGroupItem>
					<ListGroupItem>Rejestracja z: {data.userDomain}</ListGroupItem>
					<ListGroupItem>Ostatnia zmiana
						email: {data.emailChangedAt ? data.emailChangedAt : 'Brak'}</ListGroupItem>
				</ListGroup>
				{data.changeEmails.length > 0 && <div>
					<h2>Zmiany adresów email:</h2>
					<ListGroup>
						{data.changeEmails.map((change) => {
							let changeEmailElement = null;
							if (change.activatedAt) {
								changeEmailElement =
									<ListGroupItem>Zmiana <b>z {change.initialEmail} na {change.email}</b> dokonana {change.activatedAt}
									</ListGroupItem>;
							}

							if (change.deletedAt) {
								changeEmailElement =
									<ListGroupItem>Zmiana <b>z {change.initialEmail} na {change.email}</b> przerwana {change.deletedAt}
									</ListGroupItem>;
							}

							if (!changeEmailElement) {
								changeEmailElement =
									<ListGroupItem>Zmiana <b>z {change.initialEmail} na {change.email}</b> w trakcie
										(stworzona) {change.createdAt}</ListGroupItem>;
							}

							return changeEmailElement;
						})}
					</ListGroup>
				</div>}
				{data.loginIntegration && <div>
					<h2>Integracje użytkownika</h2>
					{data.loginIntegration.appleId && <ListGroup>
						<ListGroupItem>Data połączenia z Apple: {data.loginIntegration.appleConnectedAt}</ListGroupItem>
						<ListGroupItem>Czy rejestracja jest z
							Apple: {data.loginIntegration.isAppleRegistration ? 'TAK' : 'NIE'}</ListGroupItem>
					</ListGroup>}
					{data.loginIntegration.facebookId && <ListGroup>
						<ListGroupItem>Data połączenia z FB: {data.loginIntegration.facebookConnectedAt}</ListGroupItem>
						<ListGroupItem>Czy rejestracja jest z
							FB: {data.loginIntegration.isFacebookRegistration ? 'TAK' : 'NIE'}</ListGroupItem>
					</ListGroup>}
					{data.loginIntegration.googleId && <ListGroup>
						<ListGroupItem>Data połączenia z
							Google: {data.loginIntegration.googleConnectedAt}</ListGroupItem>
						<ListGroupItem>Czy rejestracja jest z
							Google: {data.loginIntegration.isGoogleRegistration ? 'TAK' : 'NIE'}</ListGroupItem>
					</ListGroup>}
				</div>}
				{data.lastMobileActivity && <div>
					<h2>Ostatnie logowanie mobile</h2>
					<ListGroup>
						<ListGroupItem>Platform: {data.lastMobileActivity.platform}</ListGroupItem>
						<ListGroupItem>Data: {data.lastMobileActivity.createdAt}</ListGroupItem>
						<ListGroupItem>Urządzenie: {data.lastMobileActivity.userAgent}</ListGroupItem>
					</ListGroup>
					{data.mobileRegistrationActivity && <div>
						<h2>Rejestracja mobile</h2>
						<ListGroup>
							<ListGroupItem>Platform: {data.mobileRegistrationActivity.platform}</ListGroupItem>
							<ListGroupItem>Data: {data.mobileRegistrationActivity.createdAt}</ListGroupItem>
							<ListGroupItem>Urządzenie: {data.mobileRegistrationActivity.userAgent}</ListGroupItem>
						</ListGroup>
					</div>}
				</div>}
			</div>
		);
	}
}

Detail.propTypes = {
	id: PropTypes.number.isRequired,
	data: PropTypes.shape({
		deleted: PropTypes.bool.isRequired,
		ban: PropTypes.shape({
			category: PropTypes.number.isRequired,
			comment: PropTypes.string,
		}),
		cashPoints: PropTypes.number.isRequired,
		prizesCount: PropTypes.number.isRequired,
		prizesPointsSum: PropTypes.number.isRequired,
		refCount: PropTypes.number.isRequired,
		refActiveCount: PropTypes.number.isRequired,
		refDeletedCount: PropTypes.number.isRequired,
		sameIp: PropTypes.number.isRequired,
		samePassword: PropTypes.number.isRequired,
		changeEmails: PropTypes.object.isRequired,
		emailChangedAt: PropTypes.object.isRequired,
		loginIntegration: PropTypes.object,
		lastMobileActivity: PropTypes.object,
		mobileRegistrationActivity: PropTypes.object
	}).isRequired,
};

function mapStateToProps(state) {
	return {
		userLogged: state.user,
	}
}

export default connect(mapStateToProps)(Detail);
