import * as React from 'react';
import PropTypes from "prop-types";
import Tooltip from "../../../../components/Tooltip";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FormattedMessage} from "react-intl";
import Button from "../../../../components/Button";

function PrintStructure(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen(!modalOpen);

  const print = () => {
    toggleModalOpen();

    setTimeout(() => {
      window.print();
    }, 350)
  }
  
  return (
    <React.Fragment>
      <i id={'structure-print'} onClick={toggleModalOpen} className="fas fa-download pointer py-2 px-1" />
      <Tooltip id={'structure-print'} msg={"Pobierz strukturę ankiety"} />
      {modalOpen && <Modal isOpen={true} toggle={toggleModalOpen} size="lg">
        <ModalHeader tag="h2" toggle={toggleModalOpen}>Pobierz strukturę ankiety</ModalHeader>
        <hr className="my-0"/>
        <ModalBody>
          Po kliknięciu poniższego przycisku "Drukuj", na oknie drukowania w opcji "Urządzenie docelowe" wybierz opcję
          "Zapisz jako PDF" w celu pobrania struktury ankiety do PDF.
        </ModalBody>
        <ModalFooter>
          <FormattedMessage id="_.button.cancel">{
            (message) =>
              <Button color="secondary" className="mb-0" onClick={toggleModalOpen}>{message}</Button>
          }</FormattedMessage>
          <Button
            color={"primary"}
            loading={false}
            onClick={print}
          >Drukuj</Button>
        </ModalFooter>
      </Modal>}
    </React.Fragment>
  );
}

PrintStructure.propTypes = {

};

export default PrintStructure;