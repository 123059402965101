import React, {Component} from 'react';
import SmtpsList from './SmtpsList';
import {FormattedMessage} from "react-intl";
import SmtpsCreateModal from "./SmtpsCreateModal";
import {Button, Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import HelpModeIconModal from "components/HelpModeIconModal";

class Smtps extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      createModal: false
    }
  }

  componentDidMount() {
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <div className="direction-group">
            <div className="direction-left d-flex justify-content-start">
              <FormattedMessage id="smtps.title">{msg => {
                return <h2 className="float-left">{msg} <HelpModeIconModal modalHeader="Poczty SMTP">
                  <p>List poczt, z których możesz skorzystać podczas wysyłania wiadomości mailowych do respondentów</p>
                </HelpModeIconModal></h2>
              }}</FormattedMessage>
            </div>
            <div className="direction-right d-md-flex justify-content-md-end mt-1 mr-1">

              <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="d-md-none">
                <DropdownToggle className="m-0 p-0" color="">
                  <span className="fas fa-ellipsis-v hoverable" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="a" onClick={() => this.toggle('createModal')} >
                    <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="smtpsButtonCreate.button"/>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <FormattedMessage id="smtpsButtonCreate.button">{
                (message) =>
                  <Button color="primary" onClick={() => this.toggle('createModal')} className="ml-3 d-none d-md-block"><i className="fas fa-plus" /> {message}</Button>
              }</FormattedMessage>

              <SmtpsCreateModal isOpen={this.state.createModal} toggle={() => this.toggle('createModal')}/>

            </div>
          </div>
        </CardHeader>
        <hr className="my-0" />
        <CardBody>
          <SmtpsList/>
        </CardBody>
      </Card>

    )
  }
}


export default Smtps;