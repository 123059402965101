import React, {Component} from 'react';
import {TabContent, TabPane} from 'reactstrap';
import FiltersQuestion from "./FiltersQuestion/FiltersQuestion";
import FiltersCollector from "./FiltersCollector/FiltersCollector";
import FilterDate from "./FilterDate/FilterDate";
import VisibleQuestions from "./VisibleQuestions/VisibleQuestions";
import FilterStatus from "./FilterStatus/FilterStatus";
import PropTypes from "prop-types";

class Data extends Component {

  render() {
  return (
    <TabContent>
      <TabPane>
        <FiltersQuestion onDeleted={() => this.props.onChange()} onSaved={() => this.props.onChange()} />
        <FiltersCollector onDeleted={() => this.props.onChange()} onSaved={() => this.props.onChange()} />
        <FilterStatus onSaved={() => this.props.onChange()} />
        <FilterDate onSaved={() => this.props.onChange()} />
        <VisibleQuestions onSaved={() => this.props.onChange()} />
      </TabPane>
    </TabContent>
  )}
}

Data.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default Data;