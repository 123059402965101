import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {deleteUser} from "reducers/user/actions";
import {Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert} from "reactstrap";
import ProfileForm from "./ProfileForm";
import setAuthorizationHeader from "utils/setAuthorizationHeader";
import {userLoggedOut} from "reducers/user/reducer";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import ProfileFormPasswordUpdate from "./ProfileFormPasswordUpdate";
import ProfileFormEmailChange from "./ProfileFormEmailChange";
import moment from "moment";

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalDelete: false,
      deleting: false,
    };

    this.deleteUser = this.deleteUser.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
  }

  componentDidMount() {
  }

  deleteUser(){
    this.setState({
      deleting: true
    });

    this.props.deleteUser().then(user => {
      localStorage.removeItem('jwt');
      localStorage.setItem('deleteAt', moment(user.deletedDate).format('YYYY-MM-DD'));
      setAuthorizationHeader();
      this.props.userLoggedOut();
    }).catch(() => {
      this.setState({
        deleting: false
      });
    });
  }

  toggleModalDelete(){
    this.setState({
      modalDelete: !this.state.modalDelete
    })
  }

  render() {
    const {user} = this.props;

    return (
      <Card>
        <CardHeader><FormattedMessage tagName="h2" id="profile.cardHeader" /></CardHeader>
        <hr className="my-0" />
        <CardBody>
          <Alert color="warning"><span>Wymagana zmiana hasła</span></Alert>
          <ProfileFormPasswordUpdate />
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps, {deleteUser, userLoggedOut})(Profile);