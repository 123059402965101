import {normalize} from "normalizr";

import api from "api";
import * as pagesActions from "../pages/actions";
import * as questionsActions from "../questions/actions";
import * as attributeLevelsActions from "../attributes/levels/actions";
import * as attributesActions from "../attributes/actions";
import * as answerActions from "../answers/actions";
import * as basketActions from "../baskets/actions";
import * as rowActions from "../rows/actions";
import * as columnActions from "../columns/actions";
import * as excludingsActions from "../excludings/actions";
import * as setsActions from "../sets/actions";
import * as blocksActions from "../blocks/actions";
import * as filtersActions from "../filters/actions";
import * as pipingsActions from "../pipings/actions";
import * as filterSerialActions from "../filterSerial/actions";
import * as filterIterativeActions from "../filterIterative/actions";
import * as quotaNewsActions from "../quotaNews/actions";
import * as scalePointsActions from "../scalePoints/actions";
import * as javascriptsActions from "../javascripts/actions";
import schemaSurvey from "entities/survey/schema";
import * as analysisSurveyActions from "../../../Analysis/reducer/survey/actions";
import {closedQuestionsCardsSet, hideInStructureSet} from "../../actions";

export const REQUEST = 'Survey/data/survey/REQUEST';
export const SUCCESS = 'Survey/data/survey/SUCCESS';
export const ERROR = 'Survey/data/survey/ERROR';

export const SET = 'Survey/data/survey/SET';
export const UPDATED = 'Survey/data/survey/UPDATED';
export const SET_SETS = 'Survey/data/survey/SET_SETS';
export const SET_ADDED = 'Survey/data/survey/SET_ADDED';
export const RE_REPORTED = 'Survey/data/survey/RE_REPORTED';
export const TEMPLATE_ADDED = 'User/TEMPLATE_ADDED';
export const TEMPLATE_DELETED = 'User/TEMPLATE_DELETED';
export const TOP_SURVEYS_COLLECTOR_ADDED = 'Survey/data/survey/TOP_SURVEYS_COLLECTOR_ADDED';
export const TOP_SURVEYS_COLLECTOR_REMOVED = 'Survey/data/survey/TOP_SURVEYS_COLLECTOR_REMOVED';

export function setSurvey(survey) {
  return {
    type: SET,
    survey
  }
}

export function updatedSurvey(surveyToMerge) {
  return {
    type: UPDATED,
    survey: surveyToMerge
  }
}

export function setSets(sets) {
  return {
    type: SET_SETS,
    sets
  }
}
export function setAdded(set) {
  return {
    type: SET_ADDED,
    set
  }
}

export function templateAdded(template) {
  return {
    type: TEMPLATE_ADDED,
    template
  }
}

export function templateDeleted(templateId) {
  return {
    type: TEMPLATE_DELETED,
    templateId
  }
}


export function surveyReReported() {
  return {
    type: RE_REPORTED,
  }
}

export function topSurveysCollectorAdded() {
  return dispatch => {
    dispatch({
      type: TOP_SURVEYS_COLLECTOR_ADDED,
    })
  }
}

export function topSurveysCollectorRemoved() {
  return dispatch => {
    dispatch({
      type: TOP_SURVEYS_COLLECTOR_REMOVED,
    })
  }
}

export function fetchSurvey(survey) {
  return dispatch => {
    dispatch({type: REQUEST});
    dispatch(setSurvey({}));

    return api.survey.get.survey(survey).then(surveyData => {

      if (localStorage.getItem('translatableLang') && !surveyData.languages.includes(localStorage.getItem('translatableLang'))) {
        localStorage.setItem('translatableLang', surveyData.defaultLang);
      }

      const normalized = normalize(surveyData, schemaSurvey);
      const survey = normalized.entities.surveys[normalized.result];

      if(normalized.entities.questions){
        let questionIds = Object.keys(normalized.entities.questions).map(id => parseInt(id));
        if(questionIds.length > 100){
          dispatch(hideInStructureSet('structure'))
          dispatch(closedQuestionsCardsSet(questionIds));
        }
      }


      dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
      dispatch(attributeLevelsActions.setAttributesLevels(normalized.entities.attributeLevels || {}));
      dispatch(attributesActions.setAttributes(normalized.entities.attributes || {}));
      dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
      dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
      dispatch(rowActions.setRows(normalized.entities.rows || {}));
      dispatch(columnActions.setColumns(normalized.entities.columns || {}));
      dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
      dispatch(javascriptsActions.setJavascripts(normalized.entities.javascripts || {}));
      dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
      dispatch(pagesActions.setPages(normalized.entities.pages || {}));
      dispatch(setsActions.setSets(normalized.entities.sets || {}));
      dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
      dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
      dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
      dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
      dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
      dispatch(quotaNewsActions.setQuotaNews(normalized.entities.quotaNews || {}));
      dispatch(setSurvey(survey));

      dispatch({type: SUCCESS});

      return surveyData;
    });
  }
}

export function updateSurvey(survey) {
  return dispatch => {
    return api.survey.patch.survey(survey)
      .then(survey => {
        const data = {
          defaultLang: survey.defaultLang,
          languages: survey.languages,
          name: survey.name,
          nameOryginal: survey.nameOryginal,
          nameWork: survey.nameWork,
          progressBar: survey.progressBar,
          invitationText: survey.invitationText,
          endingText: survey.endingText,
          screenOutText: survey.screenOutText,
          logo: survey.logo,
          responsesMax: survey.responsesMax,
          endSocialPoints: survey.endSocialPoints,
          endCashPoints: survey.endCashPoints,
          screenOutSocialPoints: survey.screenOutSocialPoints,
          screenOutCashPoints: survey.screenOutCashPoints,
          clientId: survey.clientId,
          paused: survey.paused,
          startAt: survey.startAt,
          endAt: survey.endAt,
          ipWhiteList: survey.ipWhiteList,
          ipBlackList: survey.ipBlackList,
          hotjarId: survey.hotjarId,
          analyticsId: survey.analyticsId,
          pauseEnable: survey.pauseEnable,
          cookieBlockade: survey.cookieBlockade,
          ipBlockade: survey.ipBlockade,
          passwordBlockade: survey.passwordBlockade,
          clearResponsesBlockade: survey.clearResponsesBlockade,
          invitationRedirect: survey.invitationRedirect,
          templateId: survey.templateId,
          shares: survey.shares,
          filtersPaused: survey.filtersPaused,
          headerText: survey.headerText,
          footerText: survey.footerText,
          description: survey.description,
          published: survey.published,
          randSets: survey.randSets,
          rotateSets: survey.rotateSets,
        };

        dispatch(updatedSurvey(data));
        dispatch(analysisSurveyActions.updatedSurvey(data));

        return survey;
      });
  }
}

export function addTemplate(data, surveyId){
  return dispatch => {
    return api.survey.post.template(data, surveyId).then(template => {
      dispatch(templateAdded(template));
    });
  }
}

export function deleteTemplate(templateId){
  return dispatch => {
    return api.survey.delete.template(templateId).then(() => {
      dispatch(templateDeleted(templateId));
    });
  }
}

export function reReportSurvey(surveyId) {
  return dispatch => {
    return api.survey.patch.swPanel.rereport(surveyId).then(() => {
      dispatch(surveyReReported())
    })
  }
}

export function filterGroupCreate(surveyId, data) {
  return dispatch => {
    return api.survey.post.filterGroupCreate(surveyId, data)
      .then(data => {
        const surveyData = data.survey;

        const normalized = normalize(surveyData, schemaSurvey);
        const survey = normalized.entities.surveys[normalized.result];

        dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
        dispatch(attributeLevelsActions.setAttributesLevels(normalized.entities.attributeLevels || {}));
        dispatch(attributesActions.setAttributes(normalized.entities.attributes || {}));
        dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
        dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
        dispatch(rowActions.setRows(normalized.entities.rows || {}));
        dispatch(columnActions.setColumns(normalized.entities.columns || {}));
        dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
        dispatch(javascriptsActions.setJavascripts(normalized.entities.javascripts || {}));
        dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
        dispatch(pagesActions.setPages(normalized.entities.pages || {}));
        dispatch(setsActions.setSets(normalized.entities.sets || {}));
        dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
        dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
        dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
        dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
        dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
        dispatch(setSurvey(survey));

        return surveyData;
      })
  }
}

export function filterSerialGroupCreate(surveyId, data) {
  return dispatch => {
    return api.survey.post.filterSerialGroupCreate(surveyId, data)
      .then(data => {
        const surveyData = data.survey;

        const normalized = normalize(surveyData, schemaSurvey);
        const survey = normalized.entities.surveys[normalized.result];

        dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
        dispatch(attributeLevelsActions.setAttributesLevels(normalized.entities.attributeLevels || {}));
        dispatch(attributesActions.setAttributes(normalized.entities.attributes || {}));
        dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
        dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
        dispatch(rowActions.setRows(normalized.entities.rows || {}));
        dispatch(columnActions.setColumns(normalized.entities.columns || {}));
        dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
        dispatch(javascriptsActions.setJavascripts(normalized.entities.javascripts || {}));
        dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
        dispatch(pagesActions.setPages(normalized.entities.pages || {}));
        dispatch(setsActions.setSets(normalized.entities.sets || {}));
        dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
        dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
        dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
        dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
        dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
        dispatch(setSurvey(survey));

        return surveyData;
      })
  }
}


export function replaceTexts(surveyId, data) {
  return dispatch => {
    return api.survey.replaceTexts(surveyId, data)
      .then(data => {
        const surveyData = data.survey;

        const normalized = normalize(surveyData, schemaSurvey);
        const survey = normalized.entities.surveys[normalized.result];

        dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
        dispatch(attributeLevelsActions.setAttributesLevels(normalized.entities.attributeLevels || {}));
        dispatch(attributesActions.setAttributes(normalized.entities.attributes || {}));
        dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
        dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
        dispatch(rowActions.setRows(normalized.entities.rows || {}));
        dispatch(columnActions.setColumns(normalized.entities.columns || {}));
        dispatch(scalePointsActions.setScalePoints(normalized.entities.scalePoints || {}));
        dispatch(javascriptsActions.setJavascripts(normalized.entities.javascripts || {}));
        dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));
        dispatch(pagesActions.setPages(normalized.entities.pages || {}));
        dispatch(setsActions.setSets(normalized.entities.sets || {}));
        dispatch(blocksActions.setBlocks(normalized.entities.blocks || {}));
        dispatch(filtersActions.setFilters(normalized.entities.filters || {}));
        dispatch(pipingsActions.setPipings(normalized.entities.pipings || {}));
        dispatch(filterSerialActions.setFilterSerial(normalized.entities.filterSerial || {}));
        dispatch(filterIterativeActions.setFilterIterative(normalized.entities.filterIterative || {}));
        dispatch(setSurvey(survey));

        return surveyData;
      })
  }
}

export function clearContentInQuestions(data) {
  return dispatch => {
    return api.survey.clearContentInQuestions(data)
      .then(data => {
        const surveyData = data.survey;

        const normalized = normalize(surveyData, schemaSurvey);

        dispatch(excludingsActions.setExcludings(normalized.entities.questionExcludings || {}));
        dispatch(answerActions.setAnswers(normalized.entities.answers || {}));
        dispatch(basketActions.setBaskets(normalized.entities.baskets || {}));
        dispatch(rowActions.setRows(normalized.entities.rows || {}));
        dispatch(columnActions.setColumns(normalized.entities.columns || {}));
        dispatch(questionsActions.setQuestions(normalized.entities.questions || {}));

        return surveyData;
      })
  }
}
