import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Tooltip,
  Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap";
import DatePicker from "react-datepicker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from "moment";
import InputNumber from "components/InputNumber";
import {toast} from "react-toastify";
import {surveyLink} from "services/surveyLink";
import StartEndDates from "./FormDefaultProperty/StartEndDates";
import CollectorResponses from "./FormDefaultProperty/CollectorResponses";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';
import SelectQuestions from "../../Survey/Structure/components/SelectQuestions";

class FormDefaultProperty extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      selectedQuestionId: null,
      tooltipQuestionCopy: false,
      tooltipGuidCopy: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTooltipQuestionCopy = this.toggleTooltipQuestionCopy.bind(this);
    this.toggleTooltipGuidCopy = this.toggleTooltipGuidCopy.bind(this);
  }

  toggleModal(){
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    })
  }

  toggleTooltipQuestionCopy(){
    this.setState({
      tooltipQuestionCopy: !this.state.tooltipQuestionCopy
    })
  }

  toggleTooltipGuidCopy(){
    this.setState({
      tooltipGuidCopy: !this.state.tooltipGuidCopy
    })
  }

  render() {
    const {tooltipGuidCopy, tooltipQuestionCopy, modalIsOpen, selectedQuestionId} = this.state;
    const {collector, errors, copyLinkWithQuestion} = this.props;

    const modalCopyQuestion = <Modal isOpen={modalIsOpen} toggle={this.toggleModal} size="xl">
      <ModalHeader tag="h2" toggle={this.toggleModal}>Link do wybranego pytania</ModalHeader>
      <hr className="my-0"/>
      <ModalBody>
        <SelectQuestions isMulti={false} onChange={(selectedQuestionId) => {
          this.setState({
            selectedQuestionId
          })
        }} />
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" className="mb-0" onClick={this.toggleModal}>{message}</Button>
        }</FormattedMessage>

        <CopyToClipboard
          text={surveyLink(collector.guid) + '?question=' + selectedQuestionId}
          onCopy={() => {
            toast.success("Skopiowano link do ankiety: " + surveyLink(collector.guid) + '?question=' + selectedQuestionId);
          }}
        >
          <LaddaButton
            className="btn btn-primary btn-ladda mb-0"
            data-style={ZOOM_OUT}
            onClick={() => {
            }}
          >
            Skopiuj link
          </LaddaButton>
        </CopyToClipboard>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Row>
          <Col className="d-flex align-items-md-stretch" xs={12} lg={4}>
            <Card className="w-100">
              <CardHeader className="text-center"><FormattedMessage tagName="h2" id="formDefaultProperty.label.guid" /></CardHeader>
              <hr className="my-0"/>
              <CardBody>
                <InputGroup className="mb-0">
                  <Input value={collector.guid} onChange={e => this.props.onPropertyChanged('guid', e.target.value)} invalid={!!errors.guid} className={this.props.disabled ? " not-active" : ""}/>
                  {copyLinkWithQuestion && <InputGroupAddon addonType="prepend" className="mr-0">
                    <InputGroupText className="pointer" id={"question-copy"} onClick={this.toggleModal}>
                      <i className="fas fa-search" />
                    </InputGroupText>
                    {modalCopyQuestion}
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipQuestionCopy}
                      target={"question-copy"}
                      toggle={this.toggleTooltipQuestionCopy}
                    >Skopiuj link do wybranego pytania</Tooltip>
                  </InputGroupAddon>}
                  <InputGroupAddon addonType="prepend" className="mr-0">
                    <InputGroupText id={"guid-copy"}>
                      <CopyToClipboard
                        text={surveyLink(collector.guid)}
                        onCopy={() => {
                          toast.success("Skopiowano link do ankiety: " + surveyLink(collector.guid));
                        }}
                      >
                        <a><i className="fas fa-copy" /></a>
                      </CopyToClipboard>
                    </InputGroupText>
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipGuidCopy}
                      target={"guid-copy"}
                      toggle={this.toggleTooltipGuidCopy}
                    >Kliknij, aby skopiować link</Tooltip>
                  </InputGroupAddon>
                </InputGroup>
                {errors.guid && <div className="invalid-feedback d-block mt-1">{errors.guid}</div>}
                <p className="mb-0 mt-1">{surveyLink(collector.guid)}</p>
              </CardBody>
            </Card>
          </Col>
          <StartEndDates
            data={_.pick(collector, ['startAt', 'endAt'])}
            onChange={(property, value) => this.props.onPropertyChanged(property, value)}
            errors={_.pick(errors, ['startAt', 'endAt'])}
          />
          <CollectorResponses
            onChange={(property, value) => this.props.onPropertyChanged(property, value)}
            data={_.pick(collector, ['collectorResponses'])}
            errors={_.pick(errors, ['collectorResponses'])}
          />
        </Row>
      </React.Fragment>
    );
  }
}

FormDefaultProperty.defaultProps = {
  disabled: false,
  copyLinkWithQuestion: false,
};

FormDefaultProperty.propTypes = {
  collector: PropTypes.shape({
    guid: PropTypes.string.isRequired,
    startAt: PropTypes.string.isRequired,
    endAt: PropTypes.string.isRequired,
    collectorResponses: PropTypes.number.isRequired,
  }).isRequired,
  onPropertyChanged: PropTypes.func.isRequired,
  surveyRouter: PropTypes.shape({
    absoluteUrl: PropTypes.string,
    absolutePath: PropTypes.string,
  }),
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  copyLinkWithQuestion: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    surveyRouter: state.config.data.surveyRouter
  }
}

export default connect(mapStateToProps)(FormDefaultProperty);