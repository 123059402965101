import api from "api";
import {userLoggedIn} from "./reducer";
import setAuthorizationHeader from "utils/setAuthorizationHeader";
import {userLoggedOut} from "reducers/user/reducer";
import {setWidgets} from "../../scenes/Dashboard/scenes/Widgets/data/widgets/actions";
import schemaUser from "../../entities/user/schema";
import {normalize} from 'normalizr';
import {setClients} from "scenes/Clients/actions";
import {setSmtps} from "scenes/Smtps/actions";

export const USER_PROFILE_UPDATED = 'User/PROFILE_UPDATED';
export const PLAN_UPDATED = 'User/PLAN_UPDATED';
export const COLUMN_UPDATED = 'User/COLUMN_UPDATED';
export const GROUP_NAME_UPDATED = 'User/GROUP_NAME_UPDATED';
export const COLUMN_DELETED = 'User/COLUMN_DELETED';
export const COLUMN_ADDED = 'User/COLUMN_ADDED';
export const FAVORITE_QUESTIONS_UPDATED = 'User/FAVORITE_QUESTIONS_UPDATED';
export const SUBSCRIPTION_CANCELED = 'User/SUBSCRIPTION_CANCELED';

export function profileUpdated(user) {
  return {
    type: USER_PROFILE_UPDATED,
    user
  }
}

export function userPlanUpdated(userPlan) {
  return {
    type: PLAN_UPDATED,
    userPlan
  }
}

export function respondentBaseColumnUpdated(respondentBaseColumn) {
  return {
    type: COLUMN_UPDATED,
    respondentBaseColumn
  }
}

export function respondentBaseColumnAdded(respondentBaseColumn) {
  return {
    type: COLUMN_ADDED,
    respondentBaseColumn
  }
}

export function groupInvitationAccepted(groupName) {
  return {
    type: GROUP_NAME_UPDATED,
    groupName
  }
}

export function respondentBaseColumnDeleted(columnId) {
  return {
    type: COLUMN_DELETED,
    columnId
  }
}

export function favoriteQuestionsUpdated(favoriteQuestions) {
  return {
    type: FAVORITE_QUESTIONS_UPDATED,
    favoriteQuestions
  }
}

export function subscriptionCanceled() {
  return {
    type: SUBSCRIPTION_CANCELED,
  }
}

export const userLogin = (credentials, captcha) => (dispatch) => api.user.auth.login(credentials, captcha).then(data => {
    let orderedColumns = {};
    _.each(data.user.respondentBaseColumns, column => orderedColumns[column.id] = column);
    data.user.respondentBaseColumns = orderedColumns;
    const normalized = normalize(data.user, schemaUser);
    const user = normalized.entities.user[normalized.result];
    normalized.entities.widgets && dispatch(setWidgets(normalized.entities.widgets));
    normalized.entities.clients && dispatch(setClients(normalized.entities.clients));
    normalized.entities.smtps && dispatch(setSmtps(normalized.entities.smtps));

    localStorage.jwt = data.token;
    setAuthorizationHeader(data.token);
    dispatch(userLoggedIn(user))

    return data;
  });

export const userLogout = () => (dispatch) =>
  api.user.auth.logout().then(() => {
    localStorage.removeItem('jwt');
    setAuthorizationHeader();
    dispatch(userLoggedOut());
  });

export const userRegister = data => (dispatch) =>
  api.user.auth.registerQuick(data).then(res => {
    localStorage.jwt = res.data.token;
    setAuthorizationHeader(res.data.token);
    dispatch(userLoggedIn(res.data.user))
  });




export function updateProfile(data) {
  return dispatch => {
    return api.user.post.userUpdate(data).then((user) => {
      dispatch(profileUpdated(user));

      return user;
    });
  }
}
export function updateEmail(data) {
  return dispatch => {
    return api.user.patch.userEmail(data).then((res) => {
      dispatch(profileUpdated(res.user));

      // localStorage.jwt = res.token;
      // setAuthorizationHeader(localStorage.jwt);

      return res.user;
    });
  }
}

export function deleteUser() {
  return dispatch => {
    return api.user.post.userDelete().then((res) => {
      dispatch(profileUpdated(res.user));

      return res.user;
    });
  }
}

export function deleteCancelUser() {
  return dispatch => {
    return api.user.post.userDeleteCancel().then((res) => {
      dispatch(profileUpdated(res.user));

      return res.user;
    });
  }
}

export function updateUserByGroupOwner(data) {
  return dispatch => {
    return api.user.patch.userProfile(data).then((res) => {
      return res;
    });
  }
}

export function updateUserPlan(data){
  return dispatch => {
    return api.user.patch.userPlan(data).then(data => {
      dispatch(userPlanUpdated(data.userPlan));
    });
  }
}

export function addRespondentBaseColumn(data){
  return dispatch => {
    return api.user.post.respondentBaseColumn(data).then(respondentBaseColumn => {

      dispatch(respondentBaseColumnAdded(respondentBaseColumn));
    });
  }
}

export function updateRespondentBaseColumn(data){
  return dispatch => {
    return api.user.patch.respondentBaseColumn(data).then(respondentBaseColumn => {

      dispatch(respondentBaseColumnUpdated(respondentBaseColumn));
    });
  }
}

export function deleteRespondentBaseColumn(columnId){
  return dispatch => {
    return api.user.delete.respondentBaseColumn(columnId).then(() => {
      dispatch(respondentBaseColumnDeleted(columnId));
    });
  }
}

export function updateFavoriteQuestions(data) {
  return dispatch => {
    return api.user.patch.favoriteQuestions(data).then(favoriteQuestions => {
      dispatch(favoriteQuestionsUpdated(favoriteQuestions))
    })
  }
}

export function cancelSubscription() {
  return dispatch => {
    return api.transaction.blueMedia.post.order.cancelSub().then (() => {
      dispatch(subscriptionCanceled());
    })
  }
}

export function chooseFreePlan(planId) {
  return dispatch => {
    return api.plan.patchUserFreePlan(planId).then(userPlan => {
      dispatch(userPlanUpdated(userPlan));
    })
  }
}