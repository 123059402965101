import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import moment from "moment/moment";
import ReactTable from "react-table";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import api from "api";
import {Link} from "react-router-dom";
import appRoutes from "../../../../appRoutes";

class PointsList extends Component {

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      searching: false,
      data: [],
      page: 0,
      pageSize: 50,
      count: 0,
      sorted: []
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const filtersWereChanged = this.props.filters !== nextProps.filters;

    if (filtersWereChanged) {
      clearTimeout(this.state.searchTimeout);
      this.setState({
        searchTimeout: setTimeout(() => {
          this.fetchData({page: this.state.page, pageSize: this.state.pageSize, sorted: this.state.sorted}, nextProps);
        }, 1000)
      });
    }
  }

  fetchData(state, props) {
    this.setState({searching: true, page: state.page, pageSize: state.pageSize});

    const data = {
      page: state.page + 1,
      pageSize: state.pageSize,
      filters: {
        ...props.filters,
        sort: state.sorted
      }
    };

    api.user.post.searchPoints(data).then(res => {
      this.setState({searching: false, data: res.points, count: res.count});
    });
  }

  render() {

    const columns = [{
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.id" />,
      accessor: 'id'
    }, {
      id: 'userId',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.userId" />,
      accessor: point => point.user.id
    }, {
      id: 'createdAt',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.createdAt" />,
      accessor: point => moment(point.createdAt).format('YYYY-MM-DD HH:mm')
    }, {
      id: 'points',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.points" />,
      accessor: point => point.what === 999 ? -point.points : point.points,
    }, {
      id: 'type',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.type" />,
      accessor: point => point.type === 1 ? 'SocialPoints' : 'LOT Points'
    }, {
      id: 'what',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.what" />,
      accessor: point => <FormattedMessage id={"point.what-" + point.what} />
    }, {
      id: 'survey',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.survey" />,
      accessor: point => <Link to={appRoutes.admin.surveys.list + '?search=' + point.surveyId}>{point.surveyId}</Link>
    }, {
      id: 'userRef',
      Header: <FormattedMessage id="admin.points.listItems.tableHeader.userRef" />,
      accessor: point => point.userRef ? point.userRef.id : "",
    }];

    return <React.Fragment>
      <p className="text-center font-weight-bold" style={{fontSize: '1em'}}>Suma punktów z tabeli: {_.sum(_.map(this.state.data, d => d.points))}</p>
      <ReactTable
        minRows={0}
        ref={this.tableRef}
        className={'table-wrap'}
        data={this.state.data}
        columns={columns}
        defaultPageSize={50}
        showPagination={!_.isEmpty(this.state.data)}
        pageSizeOptions={[10,50,100,200,1000,2500,5000]}
        noDataText={<FormattedMessage id="admin.points.listItems.table.noPoints" />}
        loading={this.state.searching}
        onFetchData={state => this.fetchData(state, this.props)}
        rowsCount={this.state.count}
        manual
        onSortedChange={sorted => this.setState({sorted})}
      />
    </React.Fragment>
  }
}

PointsList.propTypes = {
  filters: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
};

export default PointsList;
