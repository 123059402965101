import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {get, isBoolean, isNumber} from "lodash";
import {Button, Row, Col, Card, CardBody, ButtonGroup, Table} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import "./PlansTable.scss"
import api from "../../../api";
import Tooltip from "../../../components/Tooltip";
import {isString} from "lodash/lang";

class PlansTable extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      period: props.isCompany ? 'annual' : 'month',
      planCategories: [],
    };

    this.getMonthlyPriceForPeriod = this.getMonthlyPriceForPeriod.bind(this);
  }

  componentDidMount() {
    api.planCategory.list()
      .then(res => {
        this.setState({
          planCategories: res.data.planCategories,
        })
      })
  }

  getMonthlyPriceForPeriod(plan) {
    switch (this.state.period) {
      case 'month':
        return Math.round(plan.price) / 100;
      case 'quarterly':
        return Math.round(plan.quarterlyPrice / 300 * 100) / 100;
      case 'annual':
        return Math.round(plan.annualPrice / 1200 * 100) / 100;
    }
  }

  displayPlanSetting(plan, setting){
    let value = get(plan, setting.path);

    if([
      'survey.responsesMax',
      'survey.questionsMax',
      'surveysActiveMax',
      'survey.collectorsMax',
      'survey.analysesMax',
    ].includes(setting.path)){
      if(value < 0){
        value = <i className="fas fa-times text-danger" />;
      }else if(value === 0){
        value = 'Bez ograniczeń';
      }
    }else if(setting.path === 'survey.dateEndMax'){
      if(value === 0){
        value = 'Bez ograniczeń';
      }else{
        value = `+${value} dni`;
      }
    }else if(setting.path === 'survey.questionsAvailable'){
      value = value.lenght;
    }else if(setting.path === 'multimedia.maxImageFileSizeKb'){
      if(value < 0){
        value = <i className="fas fa-times text-danger" />
      }else if(value === 0){
        value = 'Bez ograniczeń';
      }else{
        value = `${value} KB`;
      }
    }else if([
      'multimedia.maxImageFileSizeKb',
      'multimedia.maxAudioFileSizeKb',
      'multimedia.maxTotalSizeKb',
    ].includes(setting.path)){
      if(value < 0){
        value = <i className="fas fa-times text-danger" />
      }else if(value === 0){
        value = 'Bez ograniczeń';
      }else{
        value = `${value/1024} MB`;
      }
    }else if([
      'respondentBase.basesMax',
      'respondentBase.baseAddressesMax',
    ].includes(setting.path)){
      if(value < 0){
        value = <i className="fas fa-times text-danger" />
      }else if(value === 0){
        value = 'Bez ograniczeń';
      }
    }else if(setting.path === 'analysis.exportTypes'){
      value = value.map(exportType => this.props.intl.formatMessage({ id: `plansTable.plan.analysis.exportTypes-${exportType}` })).join(', ')
    }else if(isBoolean(value)){
      value = value ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />
    }else if(isNumber(value)){

    }else if(isString(value)){

    }else{
      let t = typeof value;
      console.log('t', t, value);
      value = 'todo!'
    }

    return <span>{value}</span>
  }

  render() {
    const {intl, plans, prizes, isCompany} = this.props;

    return (
      <React.Fragment>
        {isCompany && <Row className="mb-4">
          <Col className="text-center">
            <ButtonGroup>
              <Button onClick={() => this.setState({period: 'month'})} active={this.state.period === 'month'}><FormattedMessage id="planDetails.info.month"/></Button>
              <Button onClick={() => this.setState({period: 'quarterly'})} active={this.state.period === 'quarterly'}><FormattedMessage id="planDetails.info.quarterly"/></Button>
              <Button onClick={() => this.setState({period: 'annual'})} active={this.state.period === 'annual'}><FormattedMessage id="planDetails.info.annual"/></Button>
            </ButtonGroup>
          </Col>
        </Row>}
        <Table className="d-none d-md-table" bordered>
          <thead>
          <tr>
            <th style={{ width: '30%' }}></th>
            {plans.map((plan, id) => {
              let prize = prizes.find(prize => prize.plan.id === plan.id);

              return <th className="text-center" style={{ backgroundColor: '#f5f9fd', width: `${((100 - 30) / plans.length)}%` }}>
                <div className="bg pointer" onClick={() => this.props.onChoosePlan(plan, this.state.period)}>
                  <strong>{plan.name}{plan.price === 0 && <span id={'plan-'+plan.id+'-free-info'} className="ml-2">
                      <i className="fas fa-question-circle" />
                      <Tooltip
                        placement="top"
                        id={'plan-'+plan.id+'-free-info'}
                        msg={'Tylko dla panelistów swpanel lub studentswatch'}
                      />

                    </span>}</strong>
                  <h3 style={{fontSize: '20px'}}>{this.getMonthlyPriceForPeriod(plan)} <FormattedMessage id="plansTable.plan.currency.pln">{msg => msg}</FormattedMessage></h3>
                  {prizes.length > 0 && <h3 style={{fontSize: '20px'}}>
                    lub {prize ? prize.cost : 0} <img src={'/dashboard/img/swpanel-cashPoints.png'} style={{width: '50px'}} />
                  </h3>}
                </div>
              </th>
            })}
          </tr>
          </thead>
          <tbody>
            {this.state.planCategories.map((planCategory, key) => {
              if(planCategory.settings.filter(setting => setting.enabled).length === 0){
                return null;
              }

              return <React.Fragment>
                <tr>
                  <td colSpan={4}>
                    <h1 className="mb-0">{planCategory.name}</h1>
                  </td>
                </tr>

                {planCategory.settings.map(setting => {
                  if(!setting.enabled){
                    return null;
                  }

                  return <tr>
                    <td>
                      <span dangerouslySetInnerHTML={{__html: setting.label}} />
                      {setting.tooltip && <React.Fragment>
                        <i id={`setting-${setting.id}-tooltip`} className="fas fa-info-circle ml-2" />
                        <Tooltip placement={'right'} id={`setting-${setting.id}-tooltip`} msg={setting.tooltip} />
                      </React.Fragment>}
                    </td>
                    {plans.map((plan, id) => <td className="text-center">{this.displayPlanSetting(plan, setting)}</td>)}
                  </tr>
                })}
              </React.Fragment>
            })}

            <tr>
              <td></td>
              {plans.map((plan, id) => <td className="text-center">
                <Button color="primary" className="btn btn-primary" onClick={() => this.props.onChoosePlan(plan, this.state.period)}><FormattedMessage id="plansTable.selectPlan" /></Button>
              </td>)}

            </tr>
          </tbody>
        </Table>

        {plans.map((plan, id) => {
          let prize = prizes.find(prize => prize.plan.id === plan.id);

          return <Table className="d-table d-md-none" bordered>
            <thead>
            <th colSpan={2} className="text-center" style={{ backgroundColor: '#f5f9fd', width: `${((100 - 30) / plans.length)}%` }}>
              <div className="bg pointer" onClick={() => this.props.onChoosePlan(plan, this.state.period)}>
                <strong>{plan.name}{plan.price === 0 && <span id={'plan-'+plan.id+'-free-info'} className="ml-2">
                        <i className="fas fa-question-circle" />
                        <Tooltip
                          placement="top"
                          id={'plan-'+plan.id+'-free-info'}
                          msg={'Tylko dla panelistów swpanel lub studentswatch'}
                        />

                      </span>}</strong>
                <h3 style={{fontSize: '20px'}}>{this.getMonthlyPriceForPeriod(plan)} <FormattedMessage id="plansTable.plan.currency.pln">{msg => msg}</FormattedMessage></h3>
                {prizes.length > 0 && <h3 style={{fontSize: '20px'}}>
                  lub {prize ? prize.cost : 0} <img src={'/dashboard/img/swpanel-cashPoints.png'} style={{width: '50px'}} />
                </h3>}
              </div>
            </th>
            </thead>
            <tbody>
            {this.state.planCategories.map((planCategory, key) => {
              if(planCategory.settings.filter(setting => setting.enabled).length === 0){
                return null;
              }

              return <React.Fragment>
                <tr>
                  <td className="text-center" colSpan={2}>
                    <h1 className="mb-0">{planCategory.name}</h1>
                  </td>
                </tr>

                {planCategory.settings.map(setting => {
                  if(!setting.enabled){
                    return null;
                  }

                  return <tr>
                    <td>
                      <span dangerouslySetInnerHTML={{__html: setting.label}} />
                      {setting.tooltip && <React.Fragment>
                        <i id={`setting-${setting.id}-tooltip`} className="fas fa-info-circle ml-2" />
                        <Tooltip placement={'right'} id={`setting-${setting.id}-tooltip`} msg={setting.tooltip} />
                      </React.Fragment>}
                    </td>
                    <td className="text-center">{this.displayPlanSetting(plan, setting)}</td>
                  </tr>
                })}
              </React.Fragment>
            })}

            <tr>
              <td colSpan={2}>
                <Button color="primary" className="btn btn-primary d-block mx-auto" onClick={() => this.props.onChoosePlan(plan, this.state.period)}><FormattedMessage id="plansTable.selectPlan" /></Button>
              </td>
            </tr>
            </tbody>
          </Table>
        })}

      </React.Fragment>
    )
  }
}

PlansTable.propTypes = {
  bestseller: PropTypes.number.isRequired,
  plans: PropTypes.array.isRequired,
  prizes: PropTypes.array.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
  isCompany: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    config: state.config.data
  }
}

export default connect(mapStateToProps)(injectIntl(PlansTable));
