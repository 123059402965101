import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Input, Row, Table} from "reactstrap";
import useDidMountEffect from "../../utils/useDidMountEffect";
import api from "../../api";
import moment from "moment";
import DatePicker from "react-datepicker";

const SurveyPointsStats = props => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({
    surveyId: '',
    usersId: '',
    client: '',
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  });

  useDidMountEffect(() => {
    fetchData();
  })

  const fetchData = () => {
    setLoading(true);

    api.admin.stats.surveyPoints(filters)
      .then(res => setData(res.data))
      .finally(() => setLoading(false))
  }

  return (
    <Row className="animated fadeIn">
      <Col>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h2 className="float-left mr-4">Statystyki punktów za ankiety</h2>

            <div className="d-flex flex-row flex-nowrap">
              <div className="ml-2">
                <div className="d-flex h-100 justify-content-center align-items-center">
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filters.dateFrom)}
                    onChange={(date) => setFilters({
                      ...filters,
                      dateFrom: date.format('YYYY-MM-DD'),
                    })}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                  <div className="px-2 d-flex align-items-center"> - </div>
                  <DatePicker
                    dropdownMode="select"
                    className="form-control w-100 mb-0"
                    isClearable={false}
                    selected={moment(filters.dateTo)}
                    onChange={(date) => setFilters({
                      ...filters,
                      dateTo: date.format('YYYY-MM-DD'),
                    })}
                    dateFormat="Y-MM-DD"
                    locale={localStorage.language}
                  />
                </div>
              </div>

              <Input className="mb-0 ml-2" placeholder={"Klient"} style={{width: '150px'}} value={filters.client} onChange={e => setFilters({
                ...filters,
                client: e.target.value,
              })} />

              <Input className="mb-0 ml-2" placeholder={"user id"} style={{width: '150px'}} value={filters.usersId} onChange={e => setFilters({
                ...filters,
                usersId: e.target.value,
              })} />

              <Input className="mb-0 ml-2" placeholder={"survey id"} style={{width: '150px'}} value={filters.surveyId} onChange={e => setFilters({
                ...filters,
                surveyId: e.target.value,
              })} />

              <Button className="ml-2" onClick={fetchData}>Pobierz</Button>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <Table bordered>
              <thead>
              <tr>
                <th>Id</th>
                <th>Nazwa robocza</th>
                <th>Nazwa</th>
                <th>Klient</th>
                <th>Data utworzenia</th>
                <th>Data zakończenia</th>
                <th>Liczba wywiadów</th>
                <th>LOT Points za ankietę</th>
                <th>Suma LOT Points</th>
              </tr>
              </thead>
              <tbody>
              {loading && <tr><td colSpan={9} className="text-center"><span>Pobieranie danych...</span></td></tr>}
              {data.map(survey => <tr>
                <td>{survey.id}</td>
                <td>{survey.name}</td>
                <td>{survey.name_work}</td>
                <td>{survey.clientName}</td>
                <td>{survey.created_at}</td>
                <td>{survey.end_at}</td>
                <td>{survey.end_responses_count}</td>
                <td>{survey.end_cash_points} / {survey.end_cash_points_email}</td>
                <td>{survey.cashPointsSum}</td>
              </tr>)}

              </tbody>
              <tfoot>
              <tr className="bg-dark text-white">
                <td colSpan={6}></td>
                <td>{data.reduce((acc, item) => acc + parseInt(item.end_responses_count), 0)}</td>
                <td></td>
                <td>{data.reduce((acc, item) => acc + parseInt(item.cashPointsSum), 0)}</td>
              </tr>
              </tfoot>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

SurveyPointsStats.propTypes = {

};

export default SurveyPointsStats;
