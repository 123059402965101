import React from 'react';
import "./styles.scss";
import useDidMountEffect from "../../../utils/useDidMountEffect";
import api from "../../../api";
import StageDetailsTable from "./StageDetailsTable";
import StageBox, {statusNumberToClass} from "./StageBox";
import classnames from "classnames";
import {Alert} from "reactstrap";

const SurveyValidation = props => {
	const [stage1Data, setStage1Data] = React.useState(null);
	const [stage2Data, setStage2Data] = React.useState(null);
	const [stage3Data, setStage3Data] = React.useState(null);
	const [stage4Data, setStage4Data] = React.useState(null);
	const [stage5Data, setStage5Data] = React.useState(null);
	const [stage6Data, setStage6Data] = React.useState(null);
	const [stageSelected, setStageSelected] = React.useState(null);

	useDidMountEffect(() => {
		api.survey.get.validationStages(props.surveyId, 1).then(res => setStage1Data(res.data))
		api.survey.get.validationStages(props.surveyId, 2).then(res => setStage2Data(res.data))
		api.survey.get.validationStages(props.surveyId, 3).then(res => setStage3Data(res.data))
		api.survey.get.validationStages(props.surveyId, 4).then(res => setStage4Data(res.data))
		api.survey.get.validationStages(props.surveyId, 5).then(res => setStage5Data(res.data))
		api.survey.get.validationStages(props.surveyId, 6).then(res => setStage6Data(res.data))
	})

	const getSummaryStageStatus = () => {
		if (
			!stage1Data
			|| !stage2Data
			|| !stage3Data
			|| !stage4Data
			|| !stage5Data
			|| !stage6Data
		) {
			return null
		}

		return Math.max(stage1Data.status, stage2Data.status, stage3Data.status, stage4Data.status, stage5Data.status, stage6Data.status)
	}

	const getStageDataBySelected = () => {
		switch (stageSelected) {
			case 1:
				return stage1Data;
			case 2:
				return stage2Data;
			case 3:
				return stage3Data;
			case 4:
				return stage4Data;
			case 5:
				return stage5Data;
			case 6:
				return stage6Data;
		}
	}

	return (
		<div>
			<Alert color="info">
				<span>Test należy wykonywać przed startem lub tuż po uruchomieniu ankiety. System analizuje poprawność konfiguracji ankiety, elementów ankiety i kolektorów w momencie jej startu. Niektóre uwagi krytyczne np. dotyczące zapełnienia kolektorów mogą nie być aktualne już po kilku godzinach zbierania danych.</span>
				<br/>
				<br/>
				<span>Uwaga: Kolektory testowe nie są traktowane jako kolektor i brak kolektora innego niż kolektor testowy oznacza błąd krytyczny.</span>
			</Alert>

			<div className="stages-list d-flex flex-column flex-md-row flex-nowrap">
				<div className="col-md-9 p-0 m-0">
					<div className="row ml-0">
						<StageBox
							title={"Ustawienia ankiety"} className={"col-md-3 mr-md-3 " + classnames({
							[`stage-selected shadow border-${statusNumberToClass(stage1Data ? stage1Data.status : null)}`]: stageSelected === 1
						})} stageData={stage1Data} onSelect={() => !!stage1Data && setStageSelected(1)}
						/>
						<StageBox
							title={"Ustawienia kolektorów"} className={"col-md-3 mr-md-3 " + classnames({
							[`stage-selected shadow border-${statusNumberToClass(stage2Data ? stage2Data.status : null)}`]: stageSelected === 2
						})} stageData={stage2Data} onSelect={() => !!stage2Data && setStageSelected(2)}
						/>
						<StageBox
							title={"Struktura ankiety"} className={"col-md-3 mr-md-3 " + classnames({
							[`stage-selected shadow border-${statusNumberToClass(stage3Data ? stage3Data.status : null)}`]: stageSelected === 3
						})} stageData={stage3Data} onSelect={() => !!stage3Data && setStageSelected(3)}
						/>
						<StageBox
							title={"Wygląd UX"} className={"col-md-3 mr-md-3 " + classnames({
							[`stage-selected shadow border-${statusNumberToClass(stage4Data ? stage4Data.status : null)}`]: stageSelected === 4
						})} stageData={stage4Data} onSelect={() => !!stage4Data && setStageSelected(4)}
						/>
						<StageBox
							title={"Obciążenie respondenta"} className={"col-md-3 mr-md-3 " + classnames({
							[`stage-selected shadow border-${statusNumberToClass(stage5Data ? stage5Data.status : null)}`]: stageSelected === 5
						})} stageData={stage5Data} onSelect={() => !!stage5Data && setStageSelected(5)}
						/>
						<StageBox
							title={"Testy"} className={"col-md-3 mr-md-3 " + classnames({
							[`stage-selected shadow border-${statusNumberToClass(stage6Data ? stage6Data.status : null)}`]: stageSelected === 6
						})} stageData={stage6Data} onSelect={() => !!stage6Data && setStageSelected(6)}
						/>
					</div>
				</div>

				<div className="p-0 m-0 col-md-3">
					<StageBox
						title={"Podsumowanie"} className={'ml-md-auto'} stageData={{status: getSummaryStageStatus()}}
					/>
				</div>
			</div>

			{stageSelected && <React.Fragment>
				<h1 className="mt-4">Szczegóły</h1>
				<StageDetailsTable stageData={getStageDataBySelected()}/>
			</React.Fragment>}
		</div>
	);
};

SurveyValidation.propTypes = {};

export default SurveyValidation;
