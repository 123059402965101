import React, {Component} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalFooter, ModalBody, ModalHeader, Button} from "reactstrap";
import PropTypes from 'prop-types';
import {updateWidget, deleteWidget} from "../../../../data/widgets/actions";

import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from 'react-ladda';

import widgetTypes from '../../../WidgetTypes/types';

import CollectorsSettings from '../../../WidgetTypes/TypeCollectors/components/Settings';
import SurveyTableSettings from '../../../WidgetTypes/TypeSurveyTable/components/Settings';
import QuestionSummariesFromAnalysisSettings from '../../../WidgetTypes/TypeQuestionSummariesFromAnalysis/components/Settings';
import SurveyTable from "../../../WidgetTypes/TypeSurveyTable";
import classnames from "classnames";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import WidgetsAvailable from "components/PlanRestrictionsModals/modals/WidgetsAvailable";

class Options extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      settingsModal: false,
      dropdownOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.delete = this.delete.bind(this);
    this.hasSettings = this.hasSettings.bind(this);
    this.settingsModal = this.settingsModal.bind(this);
    this.handleUpdateWidget = this.handleUpdateWidget.bind(this);
  }

  toggle(field) {
    this.setState({
      [field]: !this.state[field]
    });
  }

  delete() {
    this.props.deleteWidget(this.props.widget);
    this.props.triggerDeleting();
    this.setState({deleteModal: false});
  }

  hasSettings() {
    return widgetTypes.find(widget => widget.type === this.props.widget.type).hasSettings;
  }

  handleUpdateWidget(data){
    this.props.widgetUpdating();

    this.props.updateWidget(data)
    .then(d => {
      this.props.widgetUpdated();
    })
    .catch(res => {
      this.props.widgetUpdated();
    });
  }

  settingsModal() {
    switch (this.props.widget.type) {
      case 'SurveyTable':
        return <SurveyTableSettings modal={this.state.settingsModal} toggle={this.toggle} widget={this.props.widget} handleSettingsSet={this.handleUpdateWidget} handleDataChange={this.props.handleDataChange}/>;
      case 'Collectors':
        return <CollectorsSettings modal={this.state.settingsModal} toggle={this.toggle} widget={this.props.widget} handleSettingsSet={this.handleUpdateWidget} handleDataChange={this.props.handleDataChange}/>;
      case 'QuestionSummariesFromAnalysis':
        return <QuestionSummariesFromAnalysisSettings modal={this.state.settingsModal} toggle={this.toggle} widget={this.props.widget} handleSettingsSet={this.handleUpdateWidget} handleDataChange={this.props.handleDataChange}/>;
      default:
        return;
    }
  }

  render() {
    const {plan} = this.props;

    const modalDelete = <Modal isOpen={this.state.deleteModal} toggle={() => this.toggle('deleteModal')}
                         className={'modal-danger'}>
      <ModalHeader toggle={() => this.toggle('deleteModal')} tag="h2"><FormattedMessage id="widgetContainer.options.modalHeader" /></ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <p><FormattedMessage id="widgetContainer.options.modalBody" /></p>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.modal.delete.confirmation">{
          (message) => <LaddaButton
            className="btn btn-danger btn-ladda"
            data-style={ZOOM_OUT}
            onClick={() => this.delete()}
          >
            {message}
          </LaddaButton>
        }</FormattedMessage>
        <FormattedMessage id="_.button.cancel">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggle('deleteModal')}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="float-right pt-md-2">
        <DropdownToggle tag="a">
          <Button color="" className="m-0 p-0"><i className="fas fa-ellipsis-v mr-2 hoverable" /></Button>
        </DropdownToggle>
        <DropdownMenu>
          {this.hasSettings() && <DropdownItem tag="a" onClick={() => this.toggle('settingsModal')}>
            <p><i className="fas fa-edit" /></p>
            <FormattedMessage tagName="h5" id="widgetContainer.options.dropdownMenu.settings" />
          </DropdownItem>}
          {this.state.settingsModal && this.settingsModal()}
          <DropdownItem tag="a" onClick={() => plan.widgets ? this.toggle('deleteModal') : this.props.setPlanRestrictionsModals(WidgetsAvailable.getName())}>
            <p><i className="fas fa-trash" /></p>
            <FormattedMessage tagName="h5" id="_.button.delete" />
          </DropdownItem>
          {modalDelete}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

Options.propTypes = {
  triggerDeleting: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  deleteWidget: PropTypes.func.isRequired,
  handleDataChange: PropTypes.func,
  widget: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  widgetUpdated: PropTypes.func,
  widgetUpdating: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps, {updateWidget, deleteWidget, setPlanRestrictionsModals})(Options);



