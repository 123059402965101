import React, {Component} from 'react';
import AnalysisShareForm from './AnalysisShareForm';
import AnalysisShareQuestionSummary from './AnalysisShareQuestionSummary';
import {Alert, Row, Container} from 'reactstrap';
import api from "../../../api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import withParams from "../../../utils/withParams";

class AnalysisShare extends Component {

  constructor(props) {
    super(props);

    this.state = {
      questionSummaries: null,
      loading: true,
    };

    this.setQuestionSummaries = this.setQuestionSummaries.bind(this);
  }

  componentDidMount() {
    api.analysis.get.sharedQuestionSummaries({
      guid: this.props.params.guid,
      password: ''
    })
    .then(data => {

      this.setState({
        loading: false,
        questionSummaries: data.questionSummaries,
      });
      this.setQuestionSummaries(data.questionSummaries);
    })
    .catch(res => {
      this.setState({
        loading: false,
      });
    })
  }

  setQuestionSummaries(questionSummaries) {
    this.setState({questionSummaries: questionSummaries});
  }

  render() {
    const {loading} = this.state;

    return (
      <main className="app align-items-center">
        <div className="d-flex justify-content-center align-items-center mb-2">
          <a href={"https://ankieteo.pl"} className="d-block"><img src="/dashboard/img/logo.png" /></a>
        </div>

        <Container>
          <Row className="justify-content-center" id={"analysis-share"}>
            {loading
              ? <LoadingSpinner />
              : <React.Fragment>
                {!this.state.questionSummaries && <AnalysisShareForm handleSummaries={this.setQuestionSummaries} guid={this.props.params.guid}/>}
                {(this.state.questionSummaries && this.state.questionSummaries.length === 0) ? <Alert className="d-flex align-items-center" color="info"><i className="fas fa-info-circle fa-2x mr-3" /> <span>Brak danych do wyświetlenia</span> </Alert> : _.map(this.state.questionSummaries, questionSummary => <AnalysisShareQuestionSummary key={questionSummary.id} questionSummary={questionSummary}/>)}
              </React.Fragment>
            }
          </Row>
        </Container>
      </main>
    )
  }
}

export default withParams(AnalysisShare);