import {
  SET_FORCE_PRINT,
} from './actionTypes';

const initialState = {
  forcePrint: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_FORCE_PRINT:
      return {
        ...state,
        forcePrint: action.forcePrint
      };

    default:
      return state;
  }
};