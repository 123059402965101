import React, {Component} from 'react';
import {Alert, Col, Row, Card, CardBody, CardHeader} from "reactstrap";
import {connect} from "react-redux";
import FormSmsApiToken from "./components/FormSmsApiToken";
import FormSlack from "./components/FormSlack";
import SmtpAccountsTable from "./components/SmtpAccountsTable";
import {Link} from "react-router-dom";
import appRoutes from "../../../appRoutes";

class SettingsIntegration extends Component {
  componentDidMount() {
  }

  render() {
    const {user} = this.props;

    return <Card className="h-100 mb-0">
      <CardHeader><h2><i className="fas fa-puzzle-piece mr-2" /> Integracje</h2></CardHeader>
      <hr className="my-0" />
      <CardBody>
        <Row>
          <Col xs={12} lg={4}>
            <Card className="h-100">
              <CardBody className="d-flex flex-column justify-content-between">
                <img className="d-block mx-auto mb-3" src={'/dashboard/img/smsapi-logo.png'} style={{height: '50px'}} />
                <FormSmsApiToken token={user.smsApiToken} />
              </CardBody>
            </Card>
          </Col>
          <Col className="mt-2 mt-lg-0" xs={12} lg={4}>
            <Card className="h-100">
              <CardBody className="d-flex flex-column justify-content-between">
                <h1 className="p-2 text-center d-block w-100" style={{fontSize: '2em'}}>SMTP</h1>
                {user.userPlan.plan.smtpManage ? <div>
                  <Link className="d-block w-100 btn btn-primary" to={appRoutes.account.integrationSmtp}>Zarządzaj</Link>
                </div> : <Alert color="warning" className="text-center mb-auto">Zarządzanie kontami SMTP nie jest dostępne w Twoim planie</Alert>}
              </CardBody>
            </Card>
          </Col>
          <Col className="mt-2 mt-lg-0" xs={12} lg={4}>
            <Card className="h-100">
              <CardBody className="d-flex flex-column justify-content-between">
                <img className="d-block mx-auto mb-3" src={'/dashboard/img/slack-logo.png'} style={{height: '50px'}} />
                {user.userPlan.plan.slackIntegration
                  ? <FormSlack
                    user={user}
                  />
                  : <Alert color="warning" className="text-center mb-auto">Integracja z Slack nie jest dostępna w Twoim planie</Alert>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>;
  }
}

function mapStateToProps(state) {
  return {
    collectorPanelAccess: state.user.userPlan.plan.survey.collectorPanelAccess,
    collectorSmsAccess: state.user.userPlan.plan.survey.collectorSmsAccess,
    user: state.user,
  }
}

export default connect(mapStateToProps)(SettingsIntegration);