import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from "reactstrap";
import Checkbox from 'components/Checkbox';
import {FormattedMessage} from "react-intl";

class TopSurveysTermsCheckbox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(property) {
    this.setState({[property]: !this.state[property]})
  }

  render() {
    const modal = <Modal isOpen={this.state.modal} toggle={() => this.toggle('modal')} size="xl">
      <ModalHeader toggle={() => this.toggle('modal')} tag="h2">REGULAMIN PLEBISCYTU TOP ANKIETY</ModalHeader>
      <hr className="my-0" />
      <ModalBody>
        <ol style={{listStyleType: 'upper-roman'}}>
          <li className="my-3">Wprowadzenie</li>
          <span>
            Plebiscyt Top Ankiety, zwany też plebiscytem lub konkursem, to co miesięczne zestawienie najlepiej ocenianych ankiet przez użytkowników serwisów swpanel.pl i innych serwisów należących do grupy SW Research sp. z o.o. lub jej partnerów.<br />
            Co miesiąc wynagradzamy 10 najlepszych ankiet punktami LOT Points o łącznej puli 1000 punktów.<br />
            Uczestniczyć w plebiscycie mogą wszystkie ankiety, które zostały zgłoszone do konkursu.
          </span>
          <li className="my-3">Zgłaszanie i tworzenie projektów do plebiscytu Top Ankiety</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>Jeden zarejestrowany w swpanel.pl lub użytkownik może zgłosić do 3 ankiet w każdym miesiącu niezależnie od posiadanego planu systemu Ankieteo</li>
            <li>Ankieta nie może być kopią innej utworzonej ankiety. Przez kopię ankiety rozumie się powielenie projektu za pomocą dostępnego interfejsu w systemie: </li>

            <img className="my-3" src="dashboard/img/TopSurveysTerms/1.png" style={{maxWidth: '90%'}}/>

            <span className="d-block">Ankietę należy utworzyć jako nową korzystając z opcji Tworzenia nowego projektu:</span>

            <img className="my-3" src="dashboard/img/TopSurveysTerms/2.png" style={{maxWidth: '90%'}}/>

            <li>Aby zgłosić uprzednio przygotowaną ankietę to Top Ankiety należy skorzystać  z kolektora Top ankiety  (widok poniżej)</li>

            <img className="my-3" src="dashboard/img/TopSurveysTerms/3.png" style={{maxWidth: '90%'}}/>

            <span className="d-block">lub skorzystać z szybkiego tworzenia z poziomu listy projektów (niebieska ikona z lewej strony przy projekcie)</span>

            <img className="my-3" src="dashboard/img/TopSurveysTerms/4.png" style={{maxWidth: '90%'}}/>

            <li>W obu przypadkach wymagana jest akceptacja regulaminu umieszczania ankiet w bazie ankiet.</li>

            <img className="my-3" src="dashboard/img/TopSurveysTerms/5.png" style={{maxWidth: '90%'}}/>

            <li>Projekty mogą być realizowane w oparciu o pełną funkcjonalność systemu <b>Ankieteo do wersji premium</b>, która jest opisana na stronie: <a href="https://ankieteo.pl/mozliwosci-systemu" target="_blank">https://ankieteo.pl/mozliwosci-systemu</a></li>
            <li>Ankieta która jest zgłoszona do konkursu automatycznie posiada poniższe ustawienia:</li>
            <ol style={{listStyleType: 'lower-latin'}}>
              <li>Brak możliwości edycji treści i ustawień do czasu zakończenia konkursu (ostatni dzień miesiąca)</li>
              <li>Data rozpoczęcia ankiety jest ustawiana na dzień dołączenia ankiety do konkursu</li>
              <li>Data zakończenia ankiety jest ustawiana na ostatni dzień miesiąca</li>
              <li>Jeśli ankieta otrzymała przed dodaniem do konkursu "Top ankiety" jakieś oceny - zostaną one wyzerowane</li>
              <li><u>Wszystkie dotychczas zebrane w danej ankiecie wywiady zostaną usunięte</u></li>
              <li>Ankietę można wypełnić tylko raz z jednego adresu IP</li>
              <li>Ankieta jest dostępna tylko dla zarejestrowanych w serwisach swpanel.pl i umieszczona w bazie ankiet (po akceptacji administratora)</li>
              <li>Włączona jest opcja przyznawania ocen za ankietę</li>
              <li>Maksymalna liczba możliwych wypełnień ankiety wynosi 1000 (jeden tysiąc)</li>
            </ol>
            <li>Ankietę możesz wypisać z konkursu w każdym momencie. Po wypisaniu ankiety nie bierze ona już udziału w konkursie</li>
            <li>Raz wypisanej z konkursu ankiety nie można zgłosić ponownie do Top ankiety</li>
            <li>Każda nadesłana w systemie ankieta podlega cenzurze. Ankiety o treści wulgarnej, nawołującej do nietolerancji, przemocy, obraźliwe, nie będą umieszczane w bazie ankiet ani nagradzane punktami.</li>
            <li>Projekty muszą być utworzone w systemie Ankieteo. Ankiety tworzone na systemach innych niż Ankieteo nie podlegają realizacji i ocenie.</li>
            <li>Ankiety mogą zawierać multimedia.</li>
            <li>W przypadku akceptacji projektu przez administratora i jej umieszczenia – twórca ankiety może zostać o tym powiadomiony drogą elektroniczną.</li>
            <li>Administrator w trakcie akceptacji lub realizacji projektu może zdecydować o:</li>
            <ol style={{listStyleType: 'lower-latin'}}>
              <li>Terminach startu i zakończenia badania</li>
              <li>Ostatecznej wielkości zrealizowanej próby i doborze próby</li>
              <li>Liczbie punktów, które mogą zdobyć respondenci za wypełnienie ankiety</li>
              <li>Zmianie tematu badania na adekwatny do tematyki badania</li>
              <li>Poprawkach technicznych ankiety jeśli ankieta działa niepoprawnie z braku winy twórcy ankiety</li>
            </ol>
            <li>Każda ankieta przeznaczona do moderacji w konkursie Top Ankiety musi spełniać kilka warunków:</li>
            <ol style={{listStyleType: 'lower-latin'}}>
              <li>Musi być ustawiona zgodnie z punktem II 2. Administrator sprawdza TYLKO ankiety, które są przeznaczone do umieszczenia w bazie ankiet lub dodane do konkursu Top ankiety</li>
              <li>Ankieta musi być możliwa do wypełnienia: ustawienia pytań, filtrów muszą umożliwiać jej wypełnienie w całości, aż do ekranu podziękowania za jej wypełnienie. Przykład: odrzucona zostanie ankieta, która wymusza wybranie wszystkich odpowiedzi w pytaniu wielokrotnego wyboru,</li>
              <li>Ankieta nie może zawierać wyraźnych wizualnych błędów (wynikających bądź z błędu systemu bądź z błędu ustawień). W przypadku błędów systemu moderator może skorygować ustawienia wizualne ankiety bez konsultacji z twórcą ankiety,</li>
              <li>Ankieta nie może zawierać rażących błędów ortograficznych lub składniowych oraz pustych pytań, szczególnie: Uzupełnij treść pytania, uzupełnij treść odpowiedzi</li>
              <li>Ankieta nie może naruszać praw osób trzecich i dobrych obyczajów,</li>
              <li>Ankieta nie może dotyczyć tematyki serwisów konkurencyjnych tj. podobnych do studentswatch / swpanel,</li>
              <li>Ankieta nie może reklamować produktu lub usługi,</li>
              <li>Ankieta musi zawierać co najmniej jedno pytanie nie będące pytaniem metryczkowym, o socjodemografię tj. wiek, płeć, dochód, miejsce zamieszkania itd.</li>
            </ol>
            <li>Ankiety zgłoszone do Top Ankiety nie są powiązane z limitem posiadanych, aktywnych ankiet w systemie Ankieteo.</li>
          </ol>
          <li className="my-3">OCENIANIE ANKIET</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>Każdy zalogowany uczestnik panelu może ocenić ankiety po jej ukończeniu.</li>
            <li>Ocenianie odbywa się na skali 1-10, gdzie 1 jest wartością najgorszą a 10 najlepszą.</li>
            <li>Ankiety można oceniać tylko w trakcie ich aktywności.</li>
            <li>Każda ankieta może brać udział w plebiscycie tylko w miesiącu jej utworzenia/startu i tylko jeden raz. Ankiety skopiowane nie są brane pod uwagę w plebiscycie.</li>
            <li>Aby ankieta wzięła udział w plebiscycie musi być na nią  oddanych <b>co najmniej 100 (sto) ocen</b></li>
            <li>Wymogiem uczestnictwa ankiety w plebiscycie jest ilość jej wypełnień wynosząca <b>co najmniej 250 (dwieście pięćdziesiąt)</b></li>
            <li>Ocena ankiety po miesiącu jej utworzenia nie jest liczona do średniej ocen.</li>
            <li>Na podstawie oddanych głosów tworzona jest średnia ocena użytkowników wyświetlana w Bazie Ankiet obok tytułu ankiety.</li>
            <li>Ankieta o najwyższej średniej ocen zgromadzonej w danym miesiącu otrzymuje tytuł TOP Ankiety, a kolejnych 9 tytuły Laureatów.</li>
            <li>W przypadku równej średniej (mierzonej do 2 miejsc po przecinku) o wyższej pozycji decyduje liczba oddanych głosów. W dalszej kolejności decyduje jury złożone z zarządu agencji badawczej SW Research.</li>
            <li>Podsumowanie rankingu odbywa się do 5 dnia każdego miesiąca. W trakcie podsumowania analizowane są oddawane oceny pod kątem fikcyjności kont.</li>
            <li>Wyniki będą ogłaszane co miesiąc w zakładce Konkursy oraz w dziale Ranking</li>
            <li>W plebiscycie nie są brane pod uwagę ankiety, które:</li>
            <b>
              <ol style={{listStyleType: 'lower-latin'}}>
                <li>Nie są zgłoszone prawidłowo według par.  II </li>
                <li>Są wypełniane i oceniane przez uczestnika ankiety z innych kont</li>
                <li>Zostały odrzucone przez administratora na etapie moderacji</li>
                <li>Nie osiągnęły minimalnego pułapu ocen wynoszącego 100 ocen</li>
              </ol>
            </b>
          </ol>
          <li className="my-3">NAGRODY</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>Nagrody za TOP Ankiety przyznawane są raz w miesiącu pierwszym 10 (dziesięciu) najlepiej ocenianym ankietom przez użytkowników według kolejności w bazie ankiet: <a href="http://swpanel.pl/baza-ankiet" target="_blank">http://swpanel.pl/baza-ankiet</a></li>
            <li>Każdy plebiscyt rozpoczyna się 1 dnia każdego miesiąca i kończy zgodnie z ilością dni przypadających na dany miesiąc</li>
            <li>Nagrodą za 1 (pierwsze) miejsce w plebiscycie jest 500 punktów LOT Points o wartości 250 PLN</li>
            <li>Nagrodą za 2 (drugie) miejsce w plebiscycie jest 200 punktów LOT Points o wartości 100 PLN</li>
            <li>Nagrodą za 3 (drugie) miejsce w plebiscycie jest 100 punktów LOT Points o wartości 50 PLN</li>
            <li>Nagrodami za miejsca od 4 do 10 jest 25 punktów LOT Points o wartości 12,5 PLN każda</li>
            <li>Lista nagród może ulec zmianie po każdej edycji plebiscytu.</li>
          </ol>
          <li className="my-3">AKTUALIZACJE</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>SW zastrzega sobie prawo do aktualizowanie niniejszego Regulamin. Jeśli to nastąpi, data aktualizacji na Regulaminie zostanie zmieniona. Użytkownik zobowiązuje się do zapoznania się i przestrzegania zaktualizowanego Regulaminu.</li>
          </ol>
          <li className="my-3">POSTANOWIENIA KOŃCOWE</li>
          <ol style={{listStyleType: 'decimal'}}>
            <li>Niniejszy regulamin stanowi uzupełnienie regulaminu korzystania z serwisu SWPanel.pl  lub innych serwisów należących do grupy SW Research sp. z o.o. lub jej partnerów.</li>
            <li>W przypadku sytuacji, których niniejszy regulamin nie reguluje, obowiązują postanowienia regulaminu serwisu oraz korzystania z systemu Ankieteo dla użytkowników indywidualnych <a href="https://ankieteo.pl/regulamin" target="_blank">https://ankieteo.pl/regulamin</a></li>
            <li>Regulamin wchodzi w życie z dniem 1 grudnia 2019 roku i zmienia postanowienia regulaminu z 1 sierpnia 2017 roku.</li>
          </ol>
        </ol>

        <b>KONTAKT Z SW</b><br /><br />
        <span>W przypadku wątpliwości prosimy o <a href="https://swpanel.pl/kontakt" target="_blank">Kontakt</a></span>
      </ModalBody>
      <ModalFooter>
        <FormattedMessage id="_.button.close">{
          (message) =>
            <Button color="secondary" onClick={() => this.toggle('modal')}>{message}</Button>
        }</FormattedMessage>
      </ModalFooter>
    </Modal>;

    return (
      <React.Fragment>
        <Checkbox
          checked={this.props.checked}
          id={'collector-regulations-check'}
          onClick={this.props.onChange}
          text={<span>Akceptuję <a href="#" className={this.props.checked ? "" : "text-danger"} onClick={() => this.toggle('modal')}>regulamin</a> plebiscytu top ankiety</span>}
          tooltip={false}
        />
        {modal}
      </React.Fragment>
    )
  }
}

TopSurveysTermsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
};

export default TopSurveysTermsCheckbox;
