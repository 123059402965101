import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import RegisterForm from "./components/RegisterForm";
import {userRegister} from "../../../reducers/user/actions";
import appRoutes from "../../../appRoutes";
import {Link} from "react-router-dom";

class Register extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			errors: {},
			loading: false,
		};

		this.handlerOnSubmit = this.handlerOnSubmit.bind(this);
	}

	componentDidMount() {
	}

	handlerOnSubmit(data) {
		this.setState({
			loading: true,
			errors: {}
		});

		this.props.userRegister(data)
			.catch(res => {
				let errors = {};
				_.map(res.response.data.errors.children, (value, key) => {
					if (value.errors) {
						errors[key] = value.errors.join('; ');
					}
				});

				this.setState({
					loading: false,
					errors,
				});
			});
	}

	render() {
		const {loading, errors} = this.state;
		const {registerEnabled} = this.props;

		return (
			<Container>
				<Row className="mh-100vh align-items-center justify-content-center">
					<Col md="6">
						<Card className="mx-4">
							<CardBody className="p-4">
								<div className="text-center mb-4">
									<img src="/dashboard/img/logo.png" alt="ankieteo" className="logo"/>
								</div>
								<h1 className="text-center">Rejestracja</h1>

								{registerEnabled
									? <RegisterForm
										loading={loading}
										errors={errors}
										onSubmit={this.handlerOnSubmit}
									/>
									: <React.Fragment>
										<Alert color="warning" className="text-center">Rejestracja nowych kont została
											czasowo wstrzymana</Alert>
										<Link className="btn btn-secondary m-0 btn-block" to={appRoutes.users.login}><i
											className="fas fa-arrow-left"
										/> Wróć do logowania</Link>
									</React.Fragment>
								}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}

Register.propTypes = {
	registerEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
	return {
		registerEnabled: state.config.data.app.register,
	}
}

export default connect(mapStateToProps, {userRegister})(Register);