import React, {Component} from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {Form, FormGroup, Input, InputGroup, InputGroupAddon, Label} from "reactstrap";
import {ZOOM_OUT} from "react-ladda";
import LaddaButton from "react-ladda";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import appRoutes from 'appRoutes';
import SmtpAccountSelect from 'components/SmtpAccountSelect';
import api from 'api';
import FormDefaultProperty from "../../FormDefaultProperty";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";

class TabSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      data: {
        guid: props.collector.guid,
        startAt: moment(props.collector.startAt).format('YYYY-MM-DD'),
        endAt: moment(props.collector.endAt).format('YYYY-MM-DD'),
        collectorResponses: props.collector.collectorResponses,
        sendDateStart: moment(props.collector.sendDateStart).format('YYYY-MM-DD HH:mm'),
        sendPaused: props.collector.sendPaused,
        reminder: {
          count: props.collector.reminder.count,
          sendAfter: props.collector.reminder.sendAfter,
        },
      },
      errors: {}
    };

    this.onChangeReminder = this.onChangeReminder.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeReminder(e){
    this.setState({
      data: {
        ...this.state.data,
        reminder: {
          ...this.state.data.reminder,
          [e.target.name]: parseInt(e.target.value) || 0
        }
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({...this.state, saving: true, errors: {}});
    const {data} = this.state;

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: data,
    })
    .then(collector => {
      this.setState({...this.state, saving: false});
      this.props.handleUpdate(collector);
    })
    .catch(e => this.setState({
      errors: e.response.data.form.errors,
      saving: false
    }));
  }

  render() {
    const {collector} = this.props;
    const {data, saving, errors} = this.state;

    const sendPausedValues = [
      {label: 'Tak', value: true},
      {label: 'Nie', value: false}
    ];

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="mb-2">Ustawienia związane z działaniem kolektora</HelpModeIconTooltip>

        <Form onSubmit={this.onSubmit}>
          <FormDefaultProperty
            errors={errors}
            collector={data}
            onPropertyChanged={(name, value) => {
              this.setState({
                data: {
                  ...data,
                  [name]: value,
                }
              })
            }}
          />



          <a href={appRoutes.survey.collectors.collector.sms.send(collector.id)} target="_blank" className="mb-5 d-block">Wyślij zaproszenia</a>

          <FormGroup>
            <Label for="sendDateStart"><FormattedMessage id="surveyCollector.typeSms.settings.sendDateStart"/></Label>
            {<DatePicker
              dropdownMode={"select"}
              className="form-control"
              selected={moment(this.state.data.sendDateStart)}
              onChange={(sendDateStart) => {
                this.setState({data: {...data, sendDateStart: sendDateStart.format('YYYY-MM-DD HH:mm')}})
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="Y-MM-DD HH:mm"
              locale={localStorage.language}
            />}
          </FormGroup>
          <FormGroup>
            <Label for="count"><FormattedMessage id="surveyCollector.typeSms.settings.reminder.count"/></Label>
            <Input
              id="count"
              name="count"
              type="number"
              onChange={this.onChangeReminder}
              value={data.reminder.count}
            />
          </FormGroup>
          <FormGroup>
            <Label for="count"><FormattedMessage id="surveyCollector.typeSms.settings.reminder.sendAfter"/></Label>
            <InputGroup>
              <Input
                id="sendAfter"
                name="sendAfter"
                type="number"
                onChange={this.onChangeReminder}
                value={data.reminder.sendAfter}
              />
              <InputGroupAddon addonType={"append"}>h</InputGroupAddon>
            </InputGroup>
          </FormGroup>

          <div className="direction-group">
            <div className="direction-right">
              <LaddaButton
                loading={saving}
                className="btn btn-primary btn-ladda mt-2"
                data-style={ZOOM_OUT}
              >
                <FormattedMessage id="_.button.save"/>
              </LaddaButton>
            </div>
          </div>
        </Form>
      </React.Fragment>
    )
  }
}

TabSettings.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reminder: PropTypes.shape({
      count: PropTypes.number.isRequired,
      sendAfter: PropTypes.number.isRequired,
    }).isRequired,
    smtp: PropTypes.number,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default TabSettings;