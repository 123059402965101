import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Nav, NavItem, TabContent} from 'reactstrap';
import {FormattedMessage} from "react-intl";
import "react-input-range/lib/css/index.css";
import TabTarget from "./Tabs/TabTarget";
import TabSendEmail from "./Tabs/TabSendEmail";
import {NavLink, Route, Switch} from "react-router-dom";
import appRoutes from "appRoutes";
import CardBody from "reactstrap/es/CardBody";
import HelpModeIconModal from "components/HelpModeIconModal";
import SurveyCollectorValidity from "../SurveyCollectorValidity";
import TabMobilePush from "./Tabs/TabMobilePush";
import {connect} from "react-redux";

class CollectorPanel extends Component {

  render() {
    const {user} = this.props;
    const {params} = this.props.match;
    let bulkEdit = false;
    if (params.collector.split(',').length > 1) {
      bulkEdit = true;
    }

    console.log(this)
    console.log(user.userPlan.plan.survey)

    return (
      <CardBody className="px-0 pb-0">
        {this.props.collector && bulkEdit === false && <SurveyCollectorValidity collector={this.props.collector} />}
        <Nav tabs>
          <NavItem>
            <NavLink className="nav-link" exact to={bulkEdit ? appRoutes.survey.collectors.collector.panel.edit(params.survey, params.collector.split(',')) : appRoutes.survey.collectors.collector.view(params.survey, params.collector)}>
              <i className="fas fa-bullseye" />
              <FormattedMessage id={'surveyCollector.typePanel.tabs.target'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className="nav-link" to={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSendEmail(params.survey, params.collector) : appRoutes.survey.collectors.collector.panel.sendEmail(params.survey, params.collector)}>
              <i className="fas fa-envelope-open" />
              <FormattedMessage id={'surveyCollector.typePanel.tabs.sendEmail'}>{msg => <span className="d-none d-lg-inline ml-2">{msg}</span>}</FormattedMessage>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent className="tab-body">
          <HelpModeIconModal className="mb-2" modalHeader="Wysyłka zaproszeń">W tej części znajdziesz wszystki informacje i ustawienia dotyczące wysyłanych wiadomości do respondentów tego kolektora</HelpModeIconModal>
          <Switch>
            <Route path={bulkEdit ? appRoutes.survey.collectors.collector.panel.edit(params.survey, params.collector.split(',')) : appRoutes.survey.collectors.collector.view(params.survey, params.collector)} exact component={() => <TabTarget collector={this.props.collector} handleUpdate={this.props.handleUpdate} bulkEdit={bulkEdit}/>} />
            {bulkEdit === false && <Route path={appRoutes.survey.collectors.collector.panel.summary()} exact component={() => <TabSendEmail collector={this.props.collector} match={this.props.match} handleUpdate={this.props.handleUpdate}/>} />}
            <Route path={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSendEmail() : appRoutes.survey.collectors.collector.panel.sendEmail()} component={() => <TabSendEmail collector={this.props.collector} match={this.props.match} handleUpdate={this.props.handleUpdate}/>} />
            {bulkEdit === false && <Route path={appRoutes.survey.collectors.collector.panel.respondents()} exact component={() => <TabSendEmail collector={this.props.collector} match={this.props.match} handleUpdate={this.props.handleUpdate}/>} />}
            <Route path={bulkEdit ? appRoutes.survey.collectors.collector.panel.bulkSettings() : appRoutes.survey.collectors.collector.panel.settings()} exact component={() => <TabSendEmail collector={this.props.collector} match={this.props.match} handleUpdate={this.props.handleUpdate}/>} />
            {bulkEdit === false && <Route path={appRoutes.survey.collectors.collector.panel.sentHistory()} exact component={() => <TabSendEmail collector={this.props.collector} match={this.props.match} handleUpdate={this.props.handleUpdate}/>} />}
          </Switch>
        </TabContent>
      </CardBody>
    )
  }
}

CollectorPanel.propTypes = {
  collector: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      survey: PropTypes.string.isRequired,
      collector: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state, props) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(CollectorPanel);