import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import appRoutes from "../../../appRoutes";
import SurveyAccess from "../../../components/PlanRestrictionsModals/modals/SurveyAccess";
import moment from "moment";
import {connect} from "react-redux";
import {Tooltip} from "reactstrap";
import LoadingSpinner from "../../../components/LoadingSpinner";
import api from "../../../api";
import SurveyReportsWarning from "./SurveyReportsWarning";
import SurveySwitcherCheckWarning from "./SurveySwitcherCheckWarning";

const SurveyName = props => {
  const [survey, setSurvey] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const hasAccess = props.surveyPlan.accessDaysAfterCreation === 0 || moment().diff(moment(props.survey.createdAt), 'days') <= props.surveyPlan.accessDaysAfterCreation;

  const route = (
    props.survey.hasActiveSurveyBase
    && !props.swPanelDefaultAccepted
  ) ? appRoutes.survey.summary(props.survey.id) : appRoutes.survey.preview.main(props.survey.id);

  const onLinkClick = (e) => {
    if (!hasAccess) {
      e.preventDefault();
      this.props.setPlanRestrictionsModals(SurveyAccess.getName());
    }
  }

  const fetchSurvey = () => {
    if(loading){
      return;
    }
    if(survey === false){
      setLoading(true);
      api.survey.get.survey({
        id: props.survey.id,
      }).then(survey => {
        setSurvey(survey);
        setLoading(false);
      })

      return false;
    }
  }

  return (
    <React.Fragment>
      <Link
        id={`s-name-${props.survey.id}-tooltip`}
        onClick={onLinkClick}
        to={route}
      >{!hasAccess && <i className="fas fa-lock text-danger mr-2" />} <strong>{props.survey.nameWork || props.survey.name}</strong></Link>
      <Tooltip
        delay={1300}
        isOpen={tooltipOpen}
        placement={"right"}
        target={`s-name-${props.survey.id}-tooltip`}
        toggle={() => {
          fetchSurvey();
          setTooltipOpen(!tooltipOpen);
        }}
      >
        {loading && <LoadingSpinner />}
        {survey && <div className="text-left">
          <span className="d-block w-100">Id ankiety: {survey.id}</span>
          <hr className="my-1" />
          <span className="d-block w-100">Przerwanych wywiadów: {survey.breakResponsesCount}</span>
          <hr className="my-1" />
          <span className="d-block w-100">LOT Points</span>
          <span className="d-block w-100"> -zakwalifikowany: {survey.endCashPoints}</span>
          <span className="d-block w-100"> -niezakwalifikowany: {survey.screenOutCashPoints}</span>
          <hr className="my-1" />
          {survey.onlineResponsesCount && <span className="d-block w-100">On-line: {survey.onlineResponsesCount[0]} (1h) / {survey.onlineResponsesCount[1]} (24h)</span>}
          <hr className="my-1" />
          <span className="d-block w-100">Rozpoczęcie: {moment(survey.startAt).format('YYYY-MM-DD')}</span>

          {(survey.clientId && props.clients[survey.clientId]) && <React.Fragment><hr className="my-1" /><span className="d-block w-100">Klient: {props.clients[survey.clientId].name}</span></React.Fragment>}
          {survey.description && <React.Fragment><hr className="my-1" /><span className="d-block w-100">Opis: {survey.description}</span></React.Fragment>}
        </div>}
      </Tooltip>
      {parseInt(props.survey.reportsCount) > 0 && <SurveyReportsWarning reportsCount={parseInt(props.survey.reportsCount)} surveyId={props.survey.id}/>}
      {props.survey.surveySwitcherChecks && <SurveySwitcherCheckWarning surveySwitcherChecks={props.survey.surveySwitcherChecks} surveyId={props.survey.id}/>}
    </React.Fragment>
  );
};

SurveyName.propTypes = {
  survey: PropTypes.shape({
    id: PropTypes.number.isRequired,
    hasActiveSurveyBase: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  swPanelDefaultAccepted: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    clients: state.clients.data.clients,
    surveyPlan: state.user.userPlan.plan.survey,
    swPanelDefaultAccepted: 'userPlan' in state.user ? state.user.userPlan.plan.survey.swPanelDefaultAccepted : false,
  }
}

export default connect(mapStateToProps)(SurveyName);