import React, {Component} from 'react';
import {Button, ButtonGroup} from "reactstrap";
import api from "api";

import {surveyLink} from "services/surveyLink";
import LoadingSpinner from "components/LoadingSpinner";
import SurveyOwnStyles from "../../SurveyOwnStyles";
import {toast} from "react-toastify";
import appRoutes from "appRoutes";
import Tooltip from "../../../../components/Tooltip";

class Preview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      iframe: true,
      width: '100%',
      guid: false,
      sizes: [
        {
          icon: <i className="fas fa-desktop"/>,
          width: '100%'
        },
        {
          icon: <i className="fas fa-tablet-alt"/>,
          width: '768px'
        },
        {
          icon: <i className="fas fa-mobile-alt"/>,
          width: '395px'
        },
      ],
      disableValidations: false,
      questionsListPreview: false,
    };

    this.ownStylesUpdated = this.ownStylesUpdated.bind(this);
    this.getIframeSrc = this.getIframeSrc.bind(this);
  }

  componentDidMount(){
    window.onbeforeunload = () => {
      api.surveyResponse.delete.temporaryResponses();
    };

    api.survey.get.temporaryCollector({
      id: this.props.match.params.survey
    }).then(collector => {
      this.setState({
        guid: collector.guid
      });
    }).catch(e => {
      toast.error(e.response.data.errors[0].message);
      this.props.history.push(appRoutes.surveys.list);
    });
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    api.surveyResponse.delete.temporaryResponses();
  }

  ownStylesUpdated(){
    this.setState({
      iframe: false,
    }, () => this.setState({
        iframe: true,
      })
    )
  }

  changeSize(width){
    this.setState({
      width
    });
  }

  getIframeSrc(){
    const {guid} = this.state;

    let url = surveyLink(guid, false, true, -1, this.props.match.params.question);

    if(this.state.disableValidations){
      url += '&disableValidations=1'
    }

    if(this.state.questionsListPreview){
      url += '&questionsListPreview=1'
    }

    return url;
  }

  render() {
    const {sizes, width, guid, iframe, disableValidations, questionsListPreview} = this.state;

    if(!guid){
      return <LoadingSpinner/>;
    }

    return (
      <div className="p-0 d-flex flex-column h-100">
        <SurveyOwnStyles surveyId={parseInt(this.props.match.params.survey)} handlerOnUpdated={this.ownStylesUpdated} />
        {iframe && <iframe
          src={this.getIframeSrc()}
          className="border-0 my-0 mx-auto d-table flex-grow-1"
          style={{
            width,
          }}
        />}

        <div className="d-flex flex-row flex-nowrap">
          <ButtonGroup className="mt-2 d-none d-md-block">
            {sizes.map((s, key) => <Button key={key} color={s.width === width ? 'primary' : 'secondary'} onClick={() => { this.changeSize(s.width); }}>{s.icon}</Button>)}
          </ButtonGroup>

          <ButtonGroup className="mt-2 ml-2">
            <Button id="disableValidations-tooltip" color={this.state.disableValidations ? 'primary' : 'secondary'} onClick={() => { this.setState({disableValidations: !disableValidations}); }}><i className="fas fa-exclamation" /></Button>
            <Tooltip id={"disableValidations-tooltip"} msg={"Wyłącz walidację pytań"} />
            <Button id="questionsListPreview-tooltip" color={this.state.questionsListPreview ? 'primary' : 'secondary'} onClick={() => { this.setState({questionsListPreview: !questionsListPreview}); }}><i className="fas fa-bars" /></Button>
            <Tooltip id={"questionsListPreview-tooltip"} msg={"Wyświetl wszystkie pytania na jednym ekranie. Podgląd nie pozwala na testowanie filtrów, rotowań oraz losowań. Umożliwia szybki przegląd wyglądu pytań."} />
          </ButtonGroup>
        </div>
      </div>
    )
  }
}

export default Preview;