import React, {Component} from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import FormDefaultProperty from "../../FormDefaultProperty";
import HelpModeIconTooltip from "components/HelpModeIconTooltip";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import {FormattedMessage} from "react-intl";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import api from "api";
import appRoutes from "../../../../../appRoutes";
import {Link} from "react-router-dom";

class TabSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      data: {
        guid: props.collector.guid,
        accessPreviewToken: props.collector.accessPreviewToken,
        startAt: moment(props.collector.startAt).format('YYYY-MM-DD'),
        endAt: moment(props.collector.endAt).format('YYYY-MM-DD'),
        collectorResponses: props.collector.collectorResponses,
      },
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAccessPreviewToken = this.onChangeAccessPreviewToken.bind(this);
  }

  onChangeAccessPreviewToken(e){
    const {data} = this.state;

    let name = e.target.name;
    let value = e.target.value.replace(' ', '');

    this.setState({
      data: {
        ...data,
        [name]: value,
      }
    })
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({...this.state, saving: true, errors: {}});
    const {data} = this.state;

    api.surveyCollector.patch({
      id: this.props.collector.id,
      surveyCollector: data,
    })
      .then(collector => {
        this.setState({...this.state, saving: false});
        this.props.handleUpdate(collector);
      })
      .catch(e => this.setState({
        errors: e.response.data.form.errors,
        saving: false
      }));
  }

  render() {
    const {data, saving, errors} = this.state;

    return (
      <React.Fragment>
        <HelpModeIconTooltip className="mb-2">Ustawienia związane z działaniem kolektora</HelpModeIconTooltip>

        <Form onSubmit={this.onSubmit}>
          <FormDefaultProperty
            errors={errors}
            collector={data}
            onPropertyChanged={(name, value) => {
              this.setState({
                data: {
                  ...data,
                  [name]: value,
                }
              })
            }}
          />

          <Row>
            <h1 className="d-block text-center w-100 mt-2">Pozostałe ustawienia</h1>
            <Col md={6}>
              <FormGroup row>
                <Label md={3} className="text-right">Hasło dostępu do pobrania listy tokenów</Label>
                <Col>
                  <Input name="accessPreviewToken" onChange={this.onChangeAccessPreviewToken} value={data.accessPreviewToken} />
                  {data.accessPreviewToken && <p>Link dostępu: <Link to={appRoutes.survey.collectors.collector.tokens.export(this.props.collector.id, data.accessPreviewToken)} target="_blank">{window.location.origin + appRoutes.survey.collectors.collector.tokens.export(this.props.collector.id, data.accessPreviewToken)}</Link></p>}
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <div className="direction-group">
            <div className="direction-right">
              <LaddaButton
                loading={saving}
                className="btn btn-primary btn-ladda mt-2"
                data-style={ZOOM_OUT}
              >
                <FormattedMessage id="_.button.save"/>
              </LaddaButton>
            </div>
          </div>
        </Form>
      </React.Fragment>
    )
  }
}

TabSettings.propTypes = {
  collector: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  handleUpdate: PropTypes.func.isRequired
};

export default TabSettings;