import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Alert} from "reactstrap";
import Chart from "../Chart/Chart";
import {FormattedMessage} from "react-intl";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

const SLICE_VALUE = 5;

class QuestionSummaryOpen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableSlice: true,
    };
  }

  render() {
    const {questionSummary} = this.props;
    const {tableSlice} = this.state;
    const dataExists = questionSummary.data.n > 0;

    if (!dataExists) {
      return <Alert color="warning text-center"><FormattedMessage id='analysis.questionSummary.noData'/></Alert>
    }

    return (
      <React.Fragment>
        <Chart id={questionSummary.question.id} type={questionSummary.chartType} n={questionSummary.data.n} data={questionSummary.data.answers.map(answer => {
          return {
            x: answer.content,
            value: parseInt(answer.value)
          }
        })}/>
        {this.props.table && <React.Fragment>
          <BootstrapTable
            className={'table-wrap w-lg-50 mx-auto mt-3'}
            data={tableSlice ? questionSummary.data.answers.slice(0, SLICE_VALUE) : questionSummary.data.answers}
            striped hover
          >
            <TableHeaderColumn dataField='content' isKey={true}><FormattedMessage
              id="analysis.questionSummary.open.tableHeader.x"/></TableHeaderColumn>
            <TableHeaderColumn dataField='value'><FormattedMessage
              id="analysis.questionSummary.open.tableHeader.value"/></TableHeaderColumn>
          </BootstrapTable>
          {(tableSlice && questionSummary.data.answers.length > SLICE_VALUE) &&
            <p className="text-center"><span className="text-muted py-2 d-block pointer"
                                             onClick={() => this.setState({tableSlice: false})}>Kliknij, aby pokazać wszystkie odpowiedzi w tabeli</span>
            </p>}
        </React.Fragment>}
      </React.Fragment>
    )
  }
}

QuestionSummaryOpen.defaultProps = {
  chart: true,
  table: true,
};

QuestionSummaryOpen.propTypes = {
  chart: PropTypes.bool,
  table: PropTypes.bool,
};

export default QuestionSummaryOpen;
