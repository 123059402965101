import React, {Component} from 'react';
import PropTypes from "prop-types";
import AnyChart from 'anychart-react'
import {Row, Col, Card, CardHeader, CardBody, Tooltip} from 'reactstrap';
import api from 'api';
import LoadingSpinner from "components/LoadingSpinner";
import {FormattedMessage, injectIntl} from "react-intl";
import HelpModeIconModal from "../../../../components/HelpModeIconModal";
import classnames from "classnames";
import StatusesSelect from "../../../../components/Survey/Analysis/StatusesSelect";
import moment from "moment";
import DatePicker from "react-datepicker";
import _ from "lodash";
import QuestionsResponsesChart from "./QuestionsResponsesChart";

class Summary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tooltip: {
        brokenOnProgress: false,
        brokenOnStart: false,
      },
      filters: {
        statuses: props.filters.statuses,
        dateFrom: props.filters.dateFrom,
        dateTo: props.filters.dateTo,
        interval: props.filters.interval,
      },
      data: {},
    };

    this.fetchData = this.fetchData.bind(this);
    this.toggleTooltipBrokenOnProgress = this.toggleTooltipBrokenOnProgress.bind(this);
    this.toggleTooltipBrokenOnStart = this.toggleTooltipBrokenOnStart.bind(this);
    this.changeFilterValue = this.changeFilterValue.bind(this);
    this.getChart = this.getChart.bind(this);
    this.getStats = this.getStats.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData(){
    const {filters} = this.state;

    this.setState({
      loading: true,
    });

    api.survey.post.summary(this.props.surveyId, filters)
    .then(data => {
      data.statuses = {
        1: data.statuses.find(s => s.status == 1) ? parseInt(data.statuses.find(s => s.status == 1).value) : 0,
        2: data.statuses.find(s => s.status == 2) ? parseInt(data.statuses.find(s => s.status == 2).value) : 0,
        3: data.statuses.find(s => s.status == 3) ? parseInt(data.statuses.find(s => s.status == 3).value) : 0,
        4: data.statuses.find(s => s.status == 4) ? parseInt(data.statuses.find(s => s.status == 4).value) : 0,
        5: data.statuses.find(s => s.status == 5) ? parseInt(data.statuses.find(s => s.status == 5).value) : 0,
      };

      this.setState({
        loading: false,
        data,
      })
    })
    .catch(e => {
      this.setState({
        loading: false,
      })
    })
  }

  toggleTooltipBrokenOnProgress(){
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        brokenOnProgress: !this.state.tooltip.brokenOnProgress
      }
    });
  }

  toggleTooltipBrokenOnStart(){
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        brokenOnStart: !this.state.tooltip.brokenOnStart
      }
    });
  }

  changeFilterValue(name, value){
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value,
      }
    }, () => {
      this.fetchData();
    })
  }

  getChart(){
    const {data} = this.state;
    let stage = anychart.graphics.create();
    let chart = anychart.area();
    chart.legend(true);

    // turn on the crosshair
    var crosshair = chart.crosshair();
    crosshair.enabled(true)
    .yStroke(null)
    .xStroke('#fff')
    .zIndex(39);
    crosshair.yLabel().enabled(false);

    chart.yScale().stackMode('value');
    chart.yScale().ticks().allowFractional(false);

    _.map(data.chartData, (d, collectorId) => {
      const collector = data.collectors.find(c => c.id == collectorId);
      let series = chart.area(_.map(_(d).toPairs().sortBy(0).fromPairs().value(), (value, key) => {
        return {
          x: key,
          value: value
        }
      }));
      series.name(collector.name || this.props.intl.formatMessage({ id: "_.surveyCollector." + collector.type + '.name' }) + ' ' + collector.position)
      .stroke('3 #fff 1')
      .fill(function() {
        return this.sourceColor + ' 0.8'
      });
      series.hovered().stroke('3 #fff 1');
      series.hovered().markers()
      .enabled(true)
      .type('circle')
      .size(4)
      .stroke('1.5 #fff');
      series.markers().zIndex(100);
    });

    return (
      // <div id="summary-chart-line" />
      <AnyChart id="responses-chart" instance={stage} charts={[chart]} height={400}/>
    )
  }

  getAvgResponseTimeFormatted(seconds){
    let minutes = Math.floor(seconds / 60);
    let sec = seconds - (minutes * 60);

    return minutes + 'min ' + sec + 'sec';
  }

  getStats(){
    const {data} = this.state;

    const resCountAll = data.statuses[1] + data.statuses[2] + data.statuses[3] + data.statuses[4] + data.statuses[5];
    const resCountWoScreen = data.statuses[1] + data.statuses[2] + data.statuses[3];

    const rrWoS = (data.statuses[3] / resCountWoScreen) * 100;
    const rrWS = (data.statuses[3] / resCountAll) * 100;

    return <div>
      {(!this.props.summary) && <p>Liczba stron: {data.pages}</p>}
      {(!this.props.summary) && <p>Liczba pytań: {data.questions}</p>}
      {(!this.props.summary || this.props.summary.statResponsesBreak) && <p>Przerwanych wywiadów: {data.statuses[1] + data.statuses[2]}
        (
        <span id="broken-on-start">{data.statuses[1]}</span>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip.brokenOnStart}
          target={'broken-on-start'}
          toggle={this.toggleTooltipBrokenOnStart}
        >
          Przerwanych na ekranie powitalnym
        </Tooltip>
        /
        <span id="broken-on-progress">{data.statuses[2]}</span>
        <Tooltip
          delay={0}
          placement="bottom"
          isOpen={this.state.tooltip.brokenOnProgress}
          target={'broken-on-progress'}
          toggle={this.toggleTooltipBrokenOnProgress}
        >
          Przerwanych w trakcie wypełniania
        </Tooltip>
        )</p>}
      {(!this.props.summary || this.props.summary.statResponsesScreenout) && <p>Niezakwalifikowanych wywiadów: {data.statuses[4] + data.statuses[5]}</p>}
      {(!this.props.summary || this.props.summary.statResponsesEnd) && <p>Zakończonych wywiadów: {data.statuses[3]}</p>}
      {(!this.props.summary || this.props.summary.statResponsesEndWithoutScreen) && <p>Zakończonych wywiadów (bez niezakwalifikowanych): {rrWoS.toFixed(1) + '%'}</p>}
      {(!this.props.summary || this.props.summary.statResponsesEndWithScreen) && <p>Zakończonych wywiadów (z niezakwalifikowanymi): {rrWS.toFixed(1) + '%'}</p>}
      {(!this.props.summary || this.props.summary.statTimeAvg) && <p>Średni czas wypełniania ankiety: {this.getAvgResponseTimeFormatted(data.avgResponseTime)}</p>}
      {(!this.props.summary || this.props.summary.statTimeMedian) && <p>Mediana czasu wypełniania ankiety: {this.getAvgResponseTimeFormatted(data.medianResponseTime)}</p>}
      {(!this.props.summary || this.props.summary.sumCashPoints) && <p>Liczba Cashpoints przekazanych za wypełnienie ankiety: {data.sumCashPoints}</p>}
    </div>
  }

  render() {
    let {loading, filters} = this.state;

    const interval = {
      hour: 'Godzina',
      day: 'Dzień',
      month: 'Miesiąc',
    };

    return (
      <React.Fragment>
        <Row className="w-100">
          <Col xs={12} lg={4} className="order-lg-2">
            <Card>
              <CardHeader className="text-center">
                <FormattedMessage id="survey.summary.cardHeader.statistics">{msg => {
                  return <h2>{msg} <HelpModeIconModal modalHeader="Podsumowanie ankiety">
                    <p>Liczba stron - całkowita w całej ankiecie</p>
                    <p>Liczba pytań - całkowita w całej ankiecie</p>
                    <p>Przerwanych wywiadów - liczba niedokończonych wypełnień ankiety</p>
                    <p>Niezakwalifikowanych wywiadów - liczba wypełnionych ankiet niespełniających kryteria</p>
                    <p>Zakończonych wywiadów - ogólna liczba zakończonych ankiet</p>
                    <p>Średni czas wypełnienia ankiety - Średni czas wypełnienia ankiet zakończonych bez niezakwalifikowanych wywiadów</p>
                  </HelpModeIconModal></h2>
                }}</FormattedMessage>
              </CardHeader>
              <hr className="my-0" />
              <CardBody>
                {loading
                  ? <LoadingSpinner />
                  : this.getStats()}
              </CardBody>
            </Card>
          </Col>
          {(!this.props.summary || this.props.summary.chartResponses) && <Col xs={12} lg={8} className="order-lg-1">
            <Card>
              <CardHeader className="text-center"><FormattedMessage tagName="h2" id="survey.summary.cardHeader.chart"/></CardHeader>
              <hr className="my-0"/>
              <CardBody>
                {!this.props.hideFilters && <Row>
                  <Col xs={12} md={4}>
                    <StatusesSelect
                      onChange={statuses => this.changeFilterValue('statuses', statuses)}
                      value={filters.statuses}
                    />
                  </Col>

                  <Col xs={12} md={4}>
                    {filters.dateFrom && filters.dateTo &&
                    <div className="d-flex h-100 justify-content-center align-items-center">
                      <DatePicker
                        dropdownMode="select"
                        className="form-control w-100 mb-0"
                        isClearable={false}
                        selected={moment(filters.dateFrom)}
                        onChange={(date) => this.changeFilterValue('dateFrom', date.format('YYYY-MM-DD'))}
                        dateFormat="Y-MM-DD"
                        locale={localStorage.language}
                      />
                      <div className="px-2 d-flex align-items-center"> -</div>
                      <DatePicker
                        dropdownMode="select"
                        className="form-control w-100 mb-0"
                        isClearable={false}
                        selected={moment(filters.dateTo)}
                        onChange={(date) => this.changeFilterValue('dateTo', date.format('YYYY-MM-DD'))}
                        dateFormat="Y-MM-DD"
                        locale={localStorage.language}
                      />
                    </div>}
                  </Col>

                  <Col xs={12} md={4} className="d-flex align-items-center">
                    <div className="btn-group w-100">
                      {_.map(interval, (name, key) => <button
                        className={classnames({
                          'btn': true,
                          'btn-primary': filters.interval === key,
                          'btn-secondary': filters.interval !== key,
                        })}
                        onClick={() => this.changeFilterValue('interval', key)}
                      >{name}</button>)}
                    </div>
                  </Col>
                </Row>}

                {this.getChart()}
              </CardBody>
            </Card>

            <Card>
              <CardHeader className="text-center"><h2>Statystyki wypełnialności pytań</h2></CardHeader>
              <hr className="my-0"/>
              <CardBody>
                <QuestionsResponsesChart
                  surveyId={this.props.surveyId}
                />
              </CardBody>
            </Card>
          </Col>}
        </Row>
      </React.Fragment>
    )
  }
}

Summary.defaultProps = {
  hideFilters: false,
  filters: {
    statuses: [3],
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    interval: 'day',
  },
};

Summary.propTypes = {
  hideFilters: PropTypes.bool,
  surveyId: PropTypes.number,
  summary: PropTypes.shape({
    chartResponses: PropTypes.bool.isRequired,
    statResponsesBreak: PropTypes.bool.isRequired,
    statResponsesScreenout: PropTypes.bool.isRequired,
    statResponsesEnd: PropTypes.bool.isRequired,
    statResponsesEndWithoutScreen: PropTypes.bool.isRequired,
    statResponsesEndWithScreen: PropTypes.bool.isRequired,
    statTimeAvg: PropTypes.bool.isRequired,
    statTimeMedian: PropTypes.bool.isRequired,
    sumCashPoints: PropTypes.number.isRequired
  }),
  filters: PropTypes.shape({
    statuses: PropTypes.array.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    interval: PropTypes.string.isRequired,
  }).isRequired,
};

export default injectIntl(Summary);