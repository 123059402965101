import React from 'react';
import PropTypes from 'prop-types'
import {Alert, Form, Row, Col, Input, InputGroup, InputGroupAddon, Button} from 'reactstrap'
import appRoutes from 'appRoutes';
import {FormattedMessage} from "react-intl";
import LaddaButton, {ZOOM_OUT} from "react-ladda";
import FormGroup from "reactstrap/es/FormGroup";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

class LoginForm extends React.Component {
  constructor(){
    super();

    this.state = {
      data: {
        username: '',
        password: '',
      },
      captcha: '',
      smsRequire: false,
      loading: false,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e){
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    })
  }

  onSubmit(e){
    e.preventDefault();

    const errors = LoginForm.validate(this.state.data);
    this.setState({ errors });

    if(Object.keys(errors).length === 0){

      this.setState({ loading: true });
      this.props.submit(this.state.data, this.state.captcha)
        .catch(e => {
          if(e.response.data.smsRequire){
            this.setState({
              smsRequire: true,
            });
          }

          this.setState({ loading: false });
          this.setState({
            errors: {
              global: e.response.data.error.message
            },
            loading: false
          })
        })
    }
  }

  static validate(data) {
    const errors = {};

    if (!data.password) errors.password = <FormattedMessage id="login.form.error.noPassword" />;
    if (!data.username) errors.username = <FormattedMessage id="login.form.error.noUsername" />;

    return errors
  }


  render() {
    const { data, errors, loading, smsRequire } = this.state;
    const {config} = this.props;

    return (
      <Row>
        <Col lg={7} sm={12}>
          <Form onSubmit={this.onSubmit} className="pt-4 pb-1">
            <img src="/dashboard/img/logo.png" alt="ankieteo" className="logo" />

            {errors.global && <Alert className="text-center" color="danger">{errors.global}</Alert>}

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <div className="input-group-text">
                  <i className="fas fa-user" />
                </div>
              </InputGroupAddon>
              <FormattedMessage id="login.form.label.username">{
                (message) =>
                  <Input name="username" type={"email"} placeholder={message} value={data.username} onChange={this.onChange}/>
              }</FormattedMessage>
            </InputGroup>
            {errors.username && <p className="help-block text-danger text-right">{errors.username}</p>}

            <InputGroup className="mt-4">
              <InputGroupAddon addonType="prepend">
                <div className="input-group-text">
                  <i className="fas fa-lock" />
                </div>
              </InputGroupAddon>
              <FormattedMessage id="login.form.label.password">{
                (message) =>
                  <Input type="password" name="password" placeholder={message} value={data.password} onChange={this.onChange}/>
              }</FormattedMessage>
            </InputGroup>
            {errors.password && <p className="help-block text-danger text-right">{errors.password}</p>}

            {smsRequire && <InputGroup className="mt-4">
              <InputGroupAddon addonType="prepend">
                <div className="input-group-text">
                  <i className="fas fa-sms"/>
                </div>
              </InputGroupAddon>
              <FormattedMessage id="login.form.label.sms">{
                (message) =>
                  <Input type="text" name="sms" placeholder={message} value={data.sms} onChange={this.onChange}/>
              }</FormattedMessage>
            </InputGroup>}
            {errors.sms && <p className="help-block text-danger text-right">{errors.sms}</p>}
            {Object.keys(errors).length > 0 && <ReCAPTCHA
                sitekey={process.env.EWZ_RECAPTCHA_SITE_KEY}
                onChange={(value) => {this.setState({captcha: value})}}
            />}
            <Row>
              <Col md={12} lg={7} className="pr-lg-1">
                <FormattedMessage id="login.form.button">{
                  (message) => <LaddaButton
                    className="btn btn-primary btn-ladda mx-auto btn-block m-0"
                    loading={loading}
                    data-style={ZOOM_OUT}
                  >
                    {message}
                  </LaddaButton>
                }</FormattedMessage>
              </Col>
            </Row>

            <FormGroup>
              <FormattedMessage id="login.form.button.forgotPassword">{
                (message) =>
                  <Link className="btn btn-link d-block mx-auto mt-4" to={appRoutes.users.forgotPassword}>{message}</Link>
              }</FormattedMessage>
            </FormGroup>
          </Form>
        </Col>
        <Col lg={5} sm={12} className="login-bg">

        </Col>
      </Row>
    )
  }
}

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    config: state.config.data
  }
}

export default connect(mapStateToProps)(LoginForm)