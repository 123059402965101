import React, {Component} from 'react';
import CreateModal from './scenes/Widgets/components/CreateModal';
import { connect } from 'react-redux';
import Widgets from "./scenes/Widgets";
import './scenes/Widgets/grid.css';
import LoadingSpinner from "components/LoadingSpinner";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Row
} from "reactstrap";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import {setPlanRestrictionsModals} from "reducers/planRestrictionsModals/actions";
import WidgetsAvailable from "components/PlanRestrictionsModals/modals/WidgetsAvailable";
import appRoutes from "appRoutes";
import AboutAnkieteo from "./scenes/aboutAnkieteo";

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      createModal: false
    }
  }

  componentDidMount() {
    const {plan, history} = this.props;

    if(!plan.widgets){
      history.push(appRoutes.surveys.list)
    }
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name]
    })
  }

  render() {
    const {plan} = this.props;

    return (
      <div>
        <Card>
          <CardHeader>
            <div className="direction-group">
              <div className="direction-left d-flex justify-content-start">
                <h2 className="float-left">Dashboard</h2>
              </div>
              <div className="direction-right d-md-flex justify-content-md-end mt-1 mr-1">

                {this.props.widgets.length > 0 && <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.toggle('dropdownOpen')} className="d-md-none">
                  <DropdownToggle className="m-0 p-0" color="">
                    <span className="fas fa-ellipsis-v hoverable" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag="a" onClick={() => plan.widgets ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(WidgetsAvailable.getName())} >
                      <p><span className="fas fa-plus" /></p> <FormattedMessage tagName="h5" id="dashboard.buttonCreate.button"/>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>}

                {this.props.widgets.length > 0 && <FormattedMessage id="dashboard.buttonCreate.button">{
                  (message) =>
                    <Button className={classnames({
                      "ml-3 d-none d-md-block": true,
                      'disabled': !plan.widgets,
                    })} color="primary" onClick={() => plan.widgets ? this.toggle('createModal') : this.props.setPlanRestrictionsModals(WidgetsAvailable.getName())}><i className="fas fa-plus" /> {message}</Button>
                }</FormattedMessage>}

                <CreateModal isOpen={this.state.createModal} toggle={() => this.toggle('createModal')}/>

              </div>
            </div>
          </CardHeader>
          <hr className="my-0" />
          <CardBody>
            <div className="float-left w-100">
              {this.props.loaded ? <Widgets widgets={this.props.widgets}/> : <LoadingSpinner/>}
            </div>
          </CardBody>
        </Card>
        {plan.widgets && <AboutAnkieteo />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    widgets: state.widgets.data.widgets ? _.map(state.user.widgets, id => state.widgets.data.widgets[id]) : [],
    loaded: state.user.id,
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps, {setPlanRestrictionsModals})(Dashboard);